export default {
  loading: false, // 全局loading
  zIndex: 2100, // 全局z-index
  userInfo: {}, // 用户信息
  leftNavList: [], // 左侧导航
  btnAuthObj: null, // 页面按钮权限
  routePath: "", // 路径
  routeName: "", // 路由组件的name
  errStatus: {
    code: -1
  }, // 全局错误状态
  XHRTimestamp: 0, // ajax请求时间戳
  toFromRoute: {
    toPath: "",
    toName: "",
    fromPath: "",
    fromName: ""
  },
  isBIMMap: "0",
  BIMType: "2",
  mapType: "",
  downSpaceCode: "", // 当前楼层
  equipAbnormalInfo: {},
  workerInfodata: {},
  isInFloor: false,
  workePupo: false,
  videoObj: {}
};
