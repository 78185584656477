export default {
  namespaced: true,
  state: {
    equipInfo: {},
    equipAbnormalInfo: {},
    searchDetail: false
  },
  mutations: {
    equipDetail(state, data) {
      state.equipInfo = data;
    },
    equipAbnormalDetail(state, data) {
      state.equipAbnormalInfo = data;
    },
    searchDetail(state, data) {
      state.searchDetail = data;
    }
  }
};
