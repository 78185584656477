export default {
  namespaced: true,
  state: {
    security: {
      longitude: "",
      latitude: ""
    },
    camera: {
      type: "",
      releationDeviceCode: []
    },
    relationCameraOpenDate: "",
    patrol: {},
    doorOperation: {}, // 门禁
    noteState: false, // 短信开启状态
    thermodynamicState: false, // 短信人员热力图
    notePersonList: null, // 短信人员列表
    therButton: null, // 热力图框选按钮确定取消
    therNoteList: null, // 框选完成后选中的人
    eventInfoData: {},
    lightUpData: {}, // 路灯更新状态
    resetMarkerType: false,
    removeIncidentNumber: "", // 需要移除的事件推送编号
    incidentNumber: null, // 井盖/垃圾桶事件号
    securityList: [], // 事件列表
    securityObjectId: "", // 事件ID
    securitySuspectId: "", // 事件SuspectId
    securityIsCamera: "", // 视频巡逻上报Tag
    securitySubtypeCode: "", // 口罩上报标识 => 是否显示布控详情
    trackData: [], // 布控详情轨迹数据
    currentPageTrack: -1, // 当前分页轨迹
    controlEventCameraInfo: {} // 布控摄像机信息
  },
  mutations: {
    securityChange(state, data) {
      // 事件详情
      state.security = data.security;
    },
    securityListChange(state, data) {
      // 事件详情
      state.securityList = data;
    },
    incidentNumberChange(state, data) {
      // 井盖/垃圾桶事件号
      state.incidentNumber = data;
    },
    cameraChange(state, data) {
      // 查看摄像机
      if (data.cameraData.ocxCameraData.length > 2) {
        let ocxCameraData = data.cameraData.ocxCameraData.splice(0, 2);
        state.camera = {
          ocxCameraData,
          type: data.cameraData.type,
          source: data.cameraData.source
        };
      } else {
        state.camera = data.cameraData;
      }
    },
    // 周界需要回放的时间
    relationCameraOpenDateUpdate(state, data) {
      state.relationCameraOpenDate = data;
    },
    patrolChange(state, data) {
      // 查看保安
      state.patrol = data.patrol;
    },
    noteStateChange(state, data) {
      state.noteState = data;
    },
    thermodynamicStateChange(state, data) {
      // 短信热力图开启关闭

      state.thermodynamicState = data;
    },
    notePersonListChange(state, data) {
      // 获取园区全部人员（短信）
      state.notePersonList = data;
    },
    therButtonChange(state, data) {
      state.therButton = data;
    },
    therNoteListChange(state, data) {
      state.therNoteList = data;
    },
    // 当前查看的事件详情的数据
    eventInfoDataChange(state, data) {
      state.eventInfoData = Object.assign({}, state.eventInfoData, data);
    },
    // 路灯提交后更新数据
    lightUpDataChange(state, data) {
      state.lightUpData = data;
    },
    resetMarkerTypeChange(state, data) {
      state.resetMarkerType = data;
    },
    // 移除事件推送
    incidentNumberRemove(state, str) {
      state.removeIncidentNumber = str;
    },
    // 获取事件ID
    getSecurityObjectId(state, data) {
      state.securityObjectId = data;
    },
    // 获取事件SuspectId
    getSecuritySuspectId(state, data) {
      state.securitySuspectId = data;
    },
    // 获取视频巡逻上报Tag
    getSecurityIsCamera(state, data) {
      state.securityIsCamera = data;
    },
    // 获取口罩上报SubtypeCode
    getSecuritySubtypeCode(state, data) {
      state.securitySubtypeCode = data;
    },
    // 获取布控详情轨迹数据
    getTrackData(state, data) {
      state.trackData = data;
    },
    changeCurrentPageTrack(state, data) {
      state.currentPageTrack = data;
    },
    getControlEventCameraInfo(state, data) {
      state.controlEventCameraInfo = data;
    },
    doorOperationChange(state, data) {
      state.doorOperation = data;
    }
  }
};
