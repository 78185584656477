import comComponents from "comComponents";
const WEBSOCKET_URL = comComponents.CONSTANT.WEBSOCKET_URL;

export const BASEURL = window.COMMON_CONFIG.requestBaseUrl;
// export const sysRegistry = {...comComponents.CONSTANT.sysRegistry, WEBSOCKET_URL};
export const iotAdminURL = comComponents.CONSTANT.iotAdminURL;// 云城APP后台
export const obsBucketNameObj = comComponents.CONSTANT.obsBucketNameObj; // 当前环境下的basemgmt 和 safetymgmt桶名
export const isThServer = comComponents.CONSTANT.isThServer;
export const sysRegistry = {
  WEBSOCKET_URL,
  SAFETYMGMT: "/ioc/safetymgmt", // 安防
  ENTERPRISE: "/ioc/enterprise", // 企业服务
  ORDER: "/ioc/order", // 付费方式
  ADMINMGMT: "/ioc/personmgmt", // 人员管理微服务
  FILEMGMT: "/ioc/filemgmt", // 文件上传微服务
  VEHICLEMGMT: "/ioc/vehiclemgmt", // 车辆管理微服务
  WEBSOCKET: "/ioc/websocket", // 长连接微服务
  BASEMGMT: "/ioc/atomiccommon", // 基础数据微服务
  BASECOMMON: "/ioc/atomiccommon", // 人员详情9171
  HOMELINK: "/ioc/homelink",
  BASEQUIPMENT: "/ioc/atomicequipment", // 设备服务9172
  BASEPERSON: "/ioc/atomicperson", // 人员列表数据
  BASEVEHICLE: "/ioc/atomicvehicle", // 车辆数据9174
  EQUIPMENTMGMT: "/ioc/equipmentmgmt", // 设备管理微服务9097
  REPORT: "/ioc/report", // 报表管理微服务9098
  SYSROLES: "/ioc/security", // 权限管理微服务9093
  ATOMICENTERPRISE: "/ioc/atomicenterprise", // 公司管理微服务9177
  MESSAGE: "/message",
  SECURITY: "/security",
  GATEWAY: "/ioc/application", // 媒体网关配置微服务8765
  ENERGY: "/ioc/newbasemgmt", // 绿色能源微服务9191
  MAINTAIN: "/ioc/maintain", // 环境配置
  REPAIR: "/ioc/repair", // 报修模块微服务9199
  EQUIPMENT: "/ioc/vanke-ioc-atomic-equipment", // 设施基础数据微服务
  DATACENTERSELFSERVICE: "/datacenterselfservice", // 数据中台自助服务
  DATACENTER: "/datacenter/api/ibp", // 新数据中台自助服务
  VIDEO: "/ioc/safetymgmt/videoforward", // 公有云视频
  PARK: "/ioc/maintain", // 获取园区视频IP
  DATACENTERFORMS: "/datacenterforms",
  PROPERTY: "/ioc/property"
};
