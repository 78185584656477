import Ajax from "@/js/api.js";
export default {
  namespaced: true,
  state: {
    blackTableData: [],
    blackPieData: {},
    // blackEventData: {},
    // blackNumData: {},
    blackPersonData: {
      totalNum: "", // 总人数
      fromPark: {
        personNum: "", // 园区自有人数
        personRatio: "" // 园区自有占比
      },
      fromPolice: {
        personNum: "", // 来自公安人数
        personRatio: "" // 来自公安占比
      }
    },
    blackBarData: null,
    perimeterInfo: {},
    videoPatrol: {},
    // reportevent: {},// 视频告警事件
    facilitySituation: {},
    FacilityAbnormality: {},
    DeviceAlarmStatusData: [],
    parkPerData: [],
    parkTraData: [],
    parkCleanData: [],
    DeviceType: [], // 设备类型列表原数据
    alarmEvent: {},
    PatrolStatics: null,
    alarmEventBar: {},
    BackListStatic: {},
    alarmClassify: {},
    backListStatus: {},
    greenEnergyObj: {},
    electrnicPieData: [],
    compareData: {
      comparePercent: "",
      lastConsume: ""
    },
    troubleData: {}
  },
  getters: {
    // 视频饼状图
    videoPiedata(state) {
      let num = 0;
      let typeNum = 100;
      let arr = [
        { value: state.perimeterInfo.prepareNum || 0, name: "待处理" },
        { value: state.perimeterInfo.underHandleNum || 0, name: "处理中" },
        { value: state.perimeterInfo.closedNum || 0, name: "已关闭" }
      ];
      for (let i = 0; i < arr.length; i++) {
        num += Number(arr[i].value);
      }

      arr.forEach((item, idx) => {
        if (num > 0) {
          if (idx < arr.length - 1) {
            item.rate = Math.round(item.value * 100 / num) + "%";
            typeNum -= Math.round(item.value * 100 / num);
          } else {
            item.rate = typeNum + "%";
          }
        } else {
          item.rate = "0%";
        }
      });

      return arr;
    },
    // 视频柱状图
    videoBarData(state) {
      let sumTime = state.videoPatrol.sumTime || [];
      let videoXAxisData = sumTime.map(item => {
        return item.replace(/\//g, "-");
      });
      let alarmNum = state.videoPatrol.alarmNum || [];
      let handleTotalNum = state.videoPatrol.handleTotalNum || [];
      let handlingTimeliness = state.videoPatrol.handlingTimeliness || [];
      let processingCompletionRate = state.videoPatrol.processingCompletionRate || [];
      let videoSeries = [
        {
          name: "累计发生",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: alarmNum,
          itemStyle: {
            color: "#1a56e5"
          }
        },
        {
          name: "累计处理",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: handleTotalNum,
          itemStyle: {
            color: "#0095da"
          }
        },
        {
          name: "处理完成率",
          type: "line",
          data: processingCompletionRate,
          yAxisIndex: 1,
          itemStyle: {
            color: "#9f5ceb"
          },
          symbol: "circle"
        },
        {
          name: "处理及时率",
          type: "line",
          data: handlingTimeliness,
          yAxisIndex: 1,
          itemStyle: {
            color: "#3dd9d9"
          },
          symbol: "circle"
        }
      ];
      let videoFormatter = "{b0}<br />{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}%<br />{a3}: {c3}%";
      return { videoXAxisData, videoSeries, videoFormatter };
    },
    // 事件处理时长表格
    tableData(state) {
      let arr = [];
      let arr1 = [];
      let avgTime = state.blackTableData.avgTime || 0;
      if (state.blackTableData) {
        arr = state.blackTableData.maxResponse || [];
        arr1 = state.blackTableData.minResponse || [];
      }
      let slowData = arr.map(item => {
        return {
          name: item.name,
          rank: item.rank,
          time: item.time
        };
      });
      let fastData = arr1.map(item => {
        return {
          name: item.name,
          rank: item.rank,
          time: item.time
        };
      });
      return { slowData, fastData, avgTime };
    },
    // 时长处理
    // eventTime(state) {
    //   let weekhour = Math.floor(state.blackEventData.week ? state.blackEventData.week / 60 : 0);
    //   let weekminute = state.blackEventData.week ? Math.floor(state.blackEventData.week) % 60 : 0;
    //   let monthhour = Math.floor(state.blackEventData.month ? state.blackEventData.month / 60 : 0);
    //   let monthminute = state.blackEventData.month ? Math.floor(state.blackEventData.month) % 60 : 0;
    //   return {
    //     weekhour,
    //     weekminute,
    //     monthhour,
    //     monthminute
    //   };
    // },
    // 黑名单事件总数
    // allEvent(state) {
    //   let num = state.blackNumData.indexBlackList;
    //   return num || 0;
    // },
    // 黑名单柱状图
    blackData(state) {
      if (!state.blackBarData) {
        return {
          blackxAxisData: [],
          blackseries: [],
          blackFormatter: "{b0}<br />{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}"
        };
      }
      let sumTime = state.blackBarData.sumTime || [];
      sumTime = sumTime.map(item => {
        return item.replace(/\//g, "-");
      });
      return {
        blackxAxisData: sumTime,
        blackseries: [
          {
            name: "累计处理",
            type: "bar",
            barWidth: "30%",
            data: state.blackBarData.totalNum || [],
            itemStyle: {
              echartsColor: [
                { offset: 0, color: "#3dc7e2" },
                { offset: 1, color: "#0095da" }
              ]
            }
          },
          {
            name: "超时未处理",
            type: "line",
            data: state.blackBarData.unhandledNum || [],
            itemStyle: {
              color: "#707070"
            },
            symbol: "circle"
          },
          {
            name: "超时后已处理",
            type: "line",
            data: state.blackBarData.handledNum || [],
            itemStyle: {
              color: "#9f5ceb"
            },
            symbol: "circle"
          }
        ],
        blackFormatter: "{b0}<br />{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}"
      };
    },
    // 设施饼状图
    facilityPieData(state) {
      let num = 0;
      let typeNum = 100;
      let arr = [
        { value: state.facilitySituation.prepareNum || 0, name: "待处理" },
        { value: state.facilitySituation.underHandleNum || 0, name: "处理中" },
        { value: state.facilitySituation.closedNum || 0, name: "已关闭" }
      ];
      for (let i = 0; i < arr.length; i++) {
        num += Number(arr[i].value);
      }
      arr.forEach((item, idx) => {
        if (num > 0) {
          if (idx < arr.length - 1) {
            item.rate = Math.round(item.value * 100 / num) + "%";
            typeNum -= Math.round(item.value * 100 / num);
          } else {
            item.rate = typeNum + "%";
          }
        } else {
          item.rate = "0%";
        }
      });
      return arr;
    },
    // 设施柱状图
    FacilityBarData(state) {
      let sumTime = state.FacilityAbnormality.sumTime || [];
      let FacilityXAxisData = sumTime.map(item => {
        return item.replace(/\//g, "-");
      });
      let FacilitySeries = [
        {
          name: "累计发生",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: state.FacilityAbnormality.cumulativeNum,
          itemStyle: {
            color: "#1a56e5"
          }
        },
        {
          name: "累计处理",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: state.FacilityAbnormality.disposeNum,
          itemStyle: {
            color: "#0095da"
          }
        },
        {
          name: "平均处理时长",
          type: "line",
          yAxisIndex: 1,
          data: state.FacilityAbnormality.disposeTime,
          itemStyle: {
            color: "#9f5ceb"
          },
          symbol: "circle"
        }
      ];
      let FacilityFormatter = "{b0}<br />{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}";
      return { FacilityXAxisData, FacilitySeries, FacilityFormatter };
    },
    // 设施下拉列表
    DeviceTypeList(state) {
      let typeList = state.DeviceType.map(item => {
        return {
          name: item.typeLvl3Cn,
          value: JSON.stringify({
            ioc_equip_type_lv1_code: item.typeLvl1Code,
            ioc_equip_type_lv2_code: item.typeLvl2Code,
            ioc_equip_type_lv3_code: item.typeLvl3Code,
            typeLvl3Cn: item.typeLvl3Cn
          })
        };
      });
      return typeList;
    },
    // 告警事件饼状图
    alarmEventPie(state) {
      let num = 0;
      let typeNum = 100;
      let arr = [
        { value: state.alarmEvent.prepareNum || 0, name: "待处理" },
        { value: state.alarmEvent.underHandleNum || 0, name: "处理中" },
        { value: state.alarmEvent.closedNum || 0, name: "已关闭" }
      ];
      for (let i = 0; i < arr.length; i++) {
        num += Number(arr[i].value);
      }

      arr.forEach((item, idx) => {
        if (num > 0) {
          if (idx < arr.length - 1) {
            item.rate = Math.round(item.value * 100 / num) + "%";
            typeNum -= Math.round(item.value * 100 / num);
          } else {
            item.rate = typeNum + "%";
          }
        } else {
          item.rate = "0%";
        }
      });
      return arr;
    },
    // 视频巡逻统计
    PatrolStaticsData(state) {
      let arr = [];
      if (state.PatrolStatics) {
        let data = state.PatrolStatics;
        arr = [
          {
            img: "patrolNum",
            num: data.patrolNum,
            unit: "执行&nbsp;(&nbsp;次&nbsp;)"
          },
          {
            img: "patrolCameraAccount",
            num: data.patrolCameraAccount,
            unit: "摄像机&nbsp;(&nbsp;个&nbsp;)"
          },
          {
            img: "signRate",
            num: `${data.signRate}%`,
            unit: "视频打卡率"
          },
          {
            img: "secondTaskTime",
            num: data.secondTaskTime,
            unit: "执行总时长&nbsp;(&nbsp;分钟&nbsp;)"
          },
          {
            img: "patrolLinesNum",
            num: data.patrolLinesNum,
            unit: "路线&nbsp;(&nbsp;条&nbsp;)"
          }
        ];
      }
      return arr;
    },
    // 事件柱状图
    alarmEventBarData(state) {
      let sumTime = state.alarmEventBar.sumTime || [];
      let EventXAxisData = sumTime.map(item => {
        return item.replace(/\//g, "-");
      });
      let EventSeries = [
        {
          name: "累计发生",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: state.alarmEventBar.alarmOccurNum,
          itemStyle: {
            color: "#1a56e5"
          }
        },
        {
          name: "累计处理",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: state.alarmEventBar.alarmDealNum,
          itemStyle: {
            color: "#0095da"
          }
        },
        {
          name: "平均处理时长",
          type: "line",
          yAxisIndex: 1,
          data: state.alarmEventBar.avgDealTime,
          itemStyle: {
            color: "#9f5ceb"
          },
          symbol: "circle"
        }
      ];
      let EventFormatter = "{b0}<br />{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}";
      return { EventXAxisData, EventSeries, EventFormatter };
    },
    // 黑名单柱状图数据
    BackListStaticData(state) {
      let sumTime = state.BackListStatic.sumTime || [];
      let XAxisData = sumTime.map(item => {
        return item.replace(/\//g, "-");
      });
      let Series = [
        {
          name: "累计发生",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: state.BackListStatic.blacklistNum,
          itemStyle: {
            color: "#1a56e5"
          }
        },
        {
          name: "累计处理",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: state.BackListStatic.processingNum,
          itemStyle: {
            color: "#0095da"
          }
        },
        {
          name: "处理完成率",
          type: "line",
          yAxisIndex: 1,
          data: state.BackListStatic.completionRate,
          itemStyle: {
            color: "#9f5ceb"
          },
          symbol: "circle"
        },
        {
          name: "处理及时率",
          type: "line",
          yAxisIndex: 1,
          data: state.BackListStatic.timelinessRate,
          itemStyle: {
            color: "#3dd9d9"
          },
          symbol: "circle"
        }
      ];
      let Formatter = "{b0}<br />{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}%<br />{a3}: {c3}%";
      return { XAxisData, Series, Formatter };
    },
    // 黑名单饼状图
    backListStatusData(state) {
      let num = 0;
      let typeNum = 100;
      let arr = [
        { value: state.backListStatus.processedNum || 0, name: "待处理" },
        { value: state.backListStatus.processingNum || 0, name: "处理中" },
        { value: state.backListStatus.closeNum || 0, name: "已关闭" }
      ];
      for (let i = 0; i < arr.length; i++) {
        num += Number(arr[i].value);
      }

      arr.forEach((item, idx) => {
        if (num > 0) {
          if (idx < arr.length - 1) {
            item.rate = Math.round(item.value * 100 / num) + "%";
            typeNum -= Math.round(item.value * 100 / num);
          } else {
            item.rate = typeNum + "%";
          }
        } else {
          item.rate = "0%";
        }
      });
      return arr;
    },
    // 绿色节能-用电统计
    greenEnergyData(state) {
      let sumTime = state.greenEnergyObj.sumTime || [];
      let EventXAxisData = sumTime.map(item => {
        return item.replace(/\//g, "-");
      });
      let EventSeries = [
        {
          name: "租户用电",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: state.greenEnergyObj.tenantEnergy,
          itemStyle: {
            color: "#1a56e5"
          }
        },
        {
          name: "公区用电",
          type: "bar",
          barWidth: "25",
          barGap: 0,
          data: state.greenEnergyObj.commonEnergy,
          itemStyle: {
            color: "#0095da"
          }
        },
        {
          name: "总用电",
          type: "line",
          // yAxisIndex: 1,
          data: state.greenEnergyObj.totalEnergy,
          itemStyle: {
            color: "#9f5ceb"
          },
          symbol: "circle"
        }
      ];
      let EventFormatter = "{b0}<br />{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}";
      return { EventXAxisData, EventSeries, EventFormatter };
    },
    electrnicData(state) {
      let num = 0;
      let typeNum = 100;
      let arr = state.electrnicPieData.map(item => {
        return {
          value: item.consume,
          name: item.energyType
        };
      });
      for (let i = 0; i < arr.length; i++) {
        num += Number(arr[i].value);
      }
      arr.forEach((item, idx) => {
        if (num > 0) {
          if (idx < arr.length - 1) {
            item.rate = Math.round(item.value * 100 / num) + "%";
            typeNum -= Math.round(item.value * 100 / num);
          } else {
            item.rate = typeNum + "%";
          }
        } else {
          item.rate = "0%";
        }
      });
      return arr;
    }
  },
  mutations: {
    perimeterInfoChange(state, data) {
      state.perimeterInfo = data;
    },
    videoPatrolChange(state, data) {
      state.videoPatrol = data;
    },
    // 黑名单
    blackTableChange(state, data) {
      state.blackTableData = data;
    },
    blackPieChange(state, data) {
      state.blackPieData = data;
    },
    blacePersonChange(state, data) {
      state.blackPersonData = data;
    },
    blackBarChange(state, data) {
      state.blackBarData = data;
    },
    facilitySituationChange(state, data) {
      state.facilitySituation = data;
    },
    FacilityAbnormalityChange(state, data) {
      state.FacilityAbnormality = data;
    },
    DeviceAlarmStatusChange(state, data) {
      state.DeviceAlarmStatusData = data;
    },
    parkPerChange(state, data) {
      state.parkPerData = data;
    },
    parkCleanChange(state, data) {
      state.parkCleanData = data;
    },
    parkTraChange(state, data) {
      state.parkTraData = data;
    },
    DeviceTypeChange(state, data) {
      state.DeviceType = data;
    },
    getAlarmEventChange(state, data) {
      state.alarmEvent = data;
    },
    getPatrolStaticsChange(state, data) {
      state.PatrolStatics = data;
    },
    getAlarmEventBarChange(state, data) {
      state.alarmEventBar = data;
    },
    BackListStaticChange(state, data) {
      state.BackListStatic = data;
    },
    alarmClassifyChange(state, data) {
      state.alarmClassify = data;
    },
    backListStatusChange(state, data) {
      state.backListStatus = data;
    },
    greenEnergyStaticChange(state, data) {
      state.greenEnergyObj = data;
    },
    electrnicPieChange(state, data) {
      state.electrnicPieData = data;
    },
    compareDataChange(state, data) {
      state.compareData = data;
    },
    troubleDataChange(state, data) {
      state.troubleData = data;
    }
  },
  actions: {
    // 巡检问题跟进状态
    getEventStatusNum({ commit }, { path, parkCode }) {
      return Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      })
        .then((data) => {
          commit("perimeterInfoChange", data);
        });
    },
    // 获取视频巡逻
    getBarGraph({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      })
        .then((data) => {
          commit("videoPatrolChange", data);
        });
    },
    // 获取黑名单 top数据
    getBlackTopData({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("blackTableChange", data);
        }
      );
    },
    // 获取黑名单柱状图
    getBlackBarData({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("blackBarChange", data);
        }
      );
    },
    // 园区人流
    getParkPerData({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("parkPerChange", data);
        }
      );
    },
    // 园区保洁
    getParkCleanData({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("parkCleanChange", data);
        }
      );
    },
    // 园区车流
    getParkTraData({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("parkTraChange", data);
        }
      );
    },
    // 设备报修概况
    getFacilitySituation({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then((data) => {
        commit("facilitySituationChange", data[0]);
      }).catch(() => {
      });
    },
    // 设备报修
    getFacilityAbnormality({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("FacilityAbnormalityChange", data);
        });
    },
    // 设施表格
    getDeviceAlarmStatusNum({ commit }, params) {
      Ajax({
        url: "/datacenter/api/datacenter/V2.1/getDeviceAlarmStatusNum",
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then((data) => {
        commit("DeviceAlarmStatusChange", data);
      });
    },
    // 设施筛选下拉列表
    getDeviceTypeList({ commit }, params) {
      Ajax({
        url: "/datacenter/api/datacenter/V2.1/getDeviceTypeList",
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then((data) => {
        commit("DeviceTypeChange", data);
      });
    },
    // 告警事件饼状图
    getAlarmEventSurvey({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("getAlarmEventChange", data[0]);
        });
    },
    // 视频巡逻统计
    getPatrolStatics({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("getPatrolStaticsChange", data);
        });
    },
    getAlarmEvent({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("getAlarmEventBarChange", data);
        });
    },
    getBackListStatic({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("BackListStaticChange", data);
        });
    },
    getAlarmClassify({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("alarmClassifyChange", data);
        });
    },
    getBackListStatus({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("backListStatusChange", data);
        });
    },
    getGreenEnergyStatic({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("greenEnergyStaticChange", data);
        });
    },
    getElectrnicPieData({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("electrnicPieChange", data);
        });
    },
    getCompareDataFunc({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("compareDataChange", data[0]);
        });
    },
    troubleDataClassify({ commit }, { path, parkCode }) {
      Ajax({
        url: path,
        method: "post",
        data: { parkCode },
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }).then(
        (data) => {
          commit("troubleDataChange", data[0]);
        });
    }
  }
};
