
export default [
  {
    // 公有云视频
    path: "/pcv",
    component: () => import(/* webpackChunkName: "pcv" */ "@/pages/pcv/index.vue"),
    redirect: "/pcv/publicCloudFaceTracking",
    children: [
      {
        path: "publicCloudFaceTracking", // 人脸追踪
        name: "publicCloudFaceTrackingIndex",
        component: () => import(/* webpackChunkName: "publicCloudFaceTrackingIndex" */ "@/pages/pcv/faceTracking") // 公有云人脸追踪
      },
      {
        path: "live",
        name: "pcvLive",
        component: () => import(/* webpackChunkName: "pcv-live" */ "@/pages/pcv/live")
      },
      {
        path: "/",
        name: "securityEventCenter",
        component: () => import(/* webpackChunkName: "securityEventCenter" */ "@/pages/securityEventCenter/securityEventCenter"), // 公有云安防事件中心
        children: [
          {
            // 消防事件中心
            path: "fireEventCenter",
            name: "fireEventCenter",
            component: () => import(/* webpackChunkName: "fireEventCenter" */ "@/pages/securityEventCenter/pages/eventList")
          },
          {
            // 消防事件详情
            path: "fireEventCenter/securityEventDetail",
            name: "fireEventSecurityEventDetail",
            component: () => import(/* webpackChunkName: "securityEventDetail" */ "@/pages/securityEventCenter/pages/securityEventDetail")
          },
          {
            // 视频巡逻
            path: "securityEventCenter", // 公有云视频方案
            name: "eventList",
            component: () => import(/* webpackChunkName: "eventList" */ "@/pages/securityEventCenter/pages/eventList")
          },
          {
            // 事件详情
            path: "securityEventCenter/securityEventDetail",
            name: "securityEventDetail",
            component: () => import(/* webpackChunkName: "securityEventDetail" */ "@/pages/securityEventCenter/pages/securityEventDetail")
          },
          {
            // 轨迹追踪
            path: "securityEventCenter/personTrajectory",
            name: "personTrajectory",
            component: () => import(/* webpackChunkName: "personTrajectory" */ "@/pages/securityEventCenter/pages/trajectoryAndHistory")
          },
          {
            // 历史事件
            path: "securityEventCenter/historyEvent",
            name: "historyEvent",
            component: () => import(/* webpackChunkName: "historyEvent" */ "@/pages/securityEventCenter/pages/trajectoryAndHistory")
          },
          {
            // 视频巡逻
            path: "videoPatrol", // 公有云视频方案
            name: "videoPatrol_index",
            component: () => import(/* webpackChunkName: "eventList" */ "@/pages/pcv/videoPatrol/pages/videoPatrol")
          },
          {
            path: "videoPatrol/videoPatrolStatistics", // 视频巡逻统计
            name: "videoPatrolStatistics",
            component: () => import(/* webpackChunkName: "videoPatrolStatistics" */ "@/pages/pcv/videoPatrol/videoPatrolStatistics")// 视频巡逻统计
          },
          {
            path: "videoPatrol/videoPatrolsList", // 视频巡逻列表
            name: "videoPatrolsList",
            component: () => import(/* webpackChunkName: "videoPatrolsList" */ "@/pages/pcv/videoPatrol/videoPatrolsList") // 视频巡逻列表
          },
          {
            path: "videoPatrol/videoPatrolsPlan", // 视频巡逻计划
            name: "videoPatrolsPlan",
            component: () => import(/* webpackChunkName: "videoPatrolsPlan" */ "@/pages/pcv/videoPatrol/videoPatrolsPlan") // 视频巡逻计划
          },
          {
            path: "videoPatrol/createPatrolPlan", // 新建视频巡逻计划
            name: "createPatrolPlan",
            component: () => import(/* webpackChunkName: "createPatrolPlan" */ "@/pages/pcv/videoPatrol/components/videoPatrolPlans/dailyMonitoring/newPatrol/index")
          },
          {
            path: "videoPatrol/modifyPatrolPlan/:id", // 修改视频巡逻计划
            name: "modifyPatrolPlan",
            component: () => import(/* webpackChunkName: "modifyPatrolPlan" */ "@/pages/pcv/videoPatrol/components/videoPatrolPlans/dailyMonitoring/newPatrol/index")
          },
          {
            path: "videoPatrol/patrolPlanDetail/:id", // 查看视频巡逻详情
            name: "patrolPlanDetail",
            component: () => import(/* webpackChunkName: "patrolPlanDetail" */ "@/pages/pcv/videoPatrol/components/videoPatrolPlans/dailyMonitoring/newPatrol/index")
          },
          {
            path: "videoPatrol/newPatrol/:id", // 新建巡逻
            name: "newPatrol",
            component: () => import(/* webpackChunkName: "newPatrol" */ "@/pages/pcv/videoPatrol/components/videoPatroLines/newPatrol")
          },
          {
            path: "videoPatrol/modificaNewPatrol/:id", // 修改巡逻
            name: "modificaNewPatrol",
            component: () => import(/* webpackChunkName: "newPatrol" */ "@/pages/pcv/videoPatrol/components/videoPatroLines/newPatrol")
          },
          {
            path: "videoPatrol/videoPatrolDetail/:id", // 视频巡逻详情
            name: "videoPatrolDetail",
            component: () => import(/* webpackChunkName: "videoPatrolDetail" */ "@/pages/pcv/videoPatrol/components/videoPatroLines/videoPatrolDetail")
          },
          {
            path: "videoPatrol/videoPatrolHistory", // 视频巡逻历史
            name: "videoPatrolHistory",
            component: () => import(/* webpackChunkName: "historyOfVideo" */ "@/pages/pcv/videoPatrol/videoPatrolHistory.vue")
          }
        ]
      }
    ]
  }
];
