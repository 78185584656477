import { RES_CODE_SUCCESS } from "@/common/utils/constant";
import axios from "axios";
import store from "@/store";

//  数据字典
import {sysRegistry, BASEURL} from "@/common/registry/sysRegistry";

const AXIOS = axios.create({
  header: {
    Accept: "*/*",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    timeout: 1000 * 60
  },
  withCredentials: true
});

let commonUtils = {
  axiosData(url, method = "get", params = {}, baseURL = BASEURL, ...configHead) {
    let isDataObject = Object.prototype.toString.call(params) === "[object Object]";
    if (isDataObject) {
      let {posCode = "", region = "", spaceModelId = ""} = store.getters["basePosition/positionInfo"];
      if (posCode && region) {
        params = {posCode, region, spaceModelId, ...params};
      }
    }

    // 兼容baseURL参数传递子服务（如：/ioc/safetymgmt）
    if (!baseURL.match("http")) {
      url = baseURL + url;
      baseURL = BASEURL;
    }

    if (/\?/.test(url)) {
      url = `${url}&_t=${new Date().getTime()}`;
    } else {
      url = `${url}?_t=${new Date().getTime()}`;
    }
    let config = {
      url,
      baseURL
    };
    method = method.toLowerCase();
    switch (method) {
      case "get":
        config.params = params;
        break;
      case "post":
        config.data = params;
        config.method = method;

        if (configHead[0]) {
          if (configHead[0].responseType) {
            config.responseType = configHead[0]["responseType"];
          } else {
            config.header = configHead[0];
            config.onUploadProgress = configHead[1];
          }
        }
        break;
      case "delete":
        config.data = params;
        config.method = method;
        break;
      case "put":
        config.data = params;
        config.method = method;
        break;
      default:
        break;
    }
    return new Promise(function(resolve, reject) {
      AXIOS.request(config)
        .then(response => {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  getRegistry(key) {
    return sysRegistry[key];
  },

  objectToQueryString(query) {
    let urlQuery = "";
    for (let key in query) {
      urlQuery += `&${key}=${query[key]}`;
    }
    urlQuery = urlQuery.substr(1);
    return urlQuery;
  },
  addEvent(obj, event, fun) {
    if (obj.addEventListener) {
      obj.addEventListener(event, fun);
    } else if (obj.attachEvent) {
      obj.attachEvent("on" + event, fun);
    } else {
      obj["on" + event] = fun;
    }
  },
  removeEvent(obj, event, fun) {
    if (obj.removeEventListener) {
      obj.removeEventListener(event, fun);
    } else if (obj.detachEvent) {
      obj.detachEvent("on" + event, fun);
    } else {
      obj["on" + event] = null;
    }
  }
};

AXIOS.interceptors.request.use(
  function(config) {
    store.commit("fillNowToXHRTimestamp");
    config.headers["websession"] = sessionStorage.getItem("IOC_WEB_SESSION");
    config.headers["lang"] = localStorage.getItem("IOC_WEB_LANG") || "zh_CN";
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);
AXIOS.interceptors.response.use(
  function(response) {
    if (response.status === 200) {
      if (response.data.code !== RES_CODE_SUCCESS) {
        store.commit("fillErrStatus", response.data.code);
      }
    }
    return response;
  },
  function(err) {
    return Promise.reject(err);
  }
);

export default commonUtils;