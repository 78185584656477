// import "babel-polyfill";
import isMatch from "lodash/isMatch";
import api from "@/js/api.js";
export default {
  uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid;
    uuid = s.join("");
    return uuid;
  },
  parseSearch() {
    let obj = {};
    location.search &&
      location.search
        .slice(1)
        .split("&")
        .forEach(item => {
          let inArr = item.split("=");
          obj[inArr[0]] = inArr[1].slice(0);
        });

    return obj;
  },
  // 判断值的数据类型
  getType(v) {
    Object.prototype.toString
      .call(v)
      .slice(8, -1)
      .toLocaleLowerCase();
  },
  // 给递归的数据赋一些值
  fillIndexToRecursionData(
    list = [],
    {
      recursionKey = "children",
      fillIndexKey = "index",
      handleIndex = o => o,
      handleItem = o => o,
      handLeafNode = o => o
    } = {}
  ) {
    let fn = (arr, coorArr) => {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        item = handleItem(item);
        let newArr = [...coorArr];
        newArr.push(i);
        item[fillIndexKey] = handleIndex(newArr);
        if (item[recursionKey] && item[recursionKey].length > 0) {
          fn(item[recursionKey], newArr);
        } else {
          item = handLeafNode(item);
          item.isRootNode = true;
        }
      }
    };
    fn(list, []);
    return list;
  },
  // getFirstLeafNode(list = [], { recursionKey = "children" } = {}) {
  //   if (!list || list.length === 0) return null;

  //   let fn = arr => {
  //     while (arr[recursionKey] && arr[recursionKey].length > 0) {
  //       return fn(arr.children[0]);
  //     }
  //     return {...arr};
  //   };
  //   return fn(list[0]);
  // },
  getFirstRootNodeCoor(list = [], { recursionKey = "children" } = {}) {
    if (!list || list.length === 0) return null;

    let fn = (arr, coorArr) => {
      while (arr[recursionKey] && arr[recursionKey].length > 0) {
        let newCoor = [...coorArr, 0];
        return fn(arr[recursionKey][0], newCoor);
      }
      return [...coorArr];
    };
    return fn(list[0], [0]);
  },
  // 根据坐标获取递归数据位置的值
  queryRecursionDataFromCoor(
    list = [],
    coorArr = [],
    { recursionKey = "children" } = {}
  ) {
    if (list.length === 0 || !coorArr || coorArr.length === 0) return null;
    let len = coorArr.length;

    let inGetTarget = function(arr, i) {
      let coor = coorArr[i];
      if (i < len - 1) {
        if (arr[coor] && arr[coor][recursionKey]) {
          return inGetTarget(arr[coor][recursionKey], i + 1);
        } else {
          console.error(
            `索引数组${JSON.stringify(coorArr)}，在当前数组中不存在`
          );
          return null;
        }
      } else {
        return arr[coor];
      }
    };

    return inGetTarget(list, 0);
  },
  // 根据坐标获取递归数据位置的值
  queryRecursionDataFromCoorDeep(
    list = [],
    coorArr = [],
    { recursionKey = "children" } = {}
  ) {
    if (list.length === 0 || !coorArr || coorArr.length === 0) return null;
    let len = coorArr.length;
    let deepArr = [];
    let inGetTarget = function(arr, i) {
      let coor = coorArr[i];
      let newObj = {};
      if (i < len - 1) {
        if (arr[coor] && arr[coor][recursionKey]) {
          for (var attr in arr[coor]) {
            if (attr !== recursionKey) {
              newObj[attr] = arr[coor][attr];
            }
          }
          deepArr.push({ ...newObj });
          return inGetTarget(arr[coor][recursionKey], i + 1);
        } else {
          console.error(
            `索引数组${JSON.stringify(coorArr)}，在当前数组中不存在`
          );
          return null;
        }
      } else {
        deepArr.push({ ...arr[coor] });
        return deepArr;
      }
    };

    return inGetTarget(list, 0);
  },

  // 根据属性获取递归数据位置的值
  queryRecursionData(
    list = [],
    target = {},
    { recursionKey = "children" } = {}
  ) {
    if (Object.keys(target).length === 0) return null;
    let notGetTarget = true;
    let result = null;
    let fn = arr => {
      if (notGetTarget && arr.length > 0) {
        for (let item of arr) {
          if (isMatch(item, target)) {
            notGetTarget = false;
            result = item;
            break;
          } else {
            if (item[recursionKey] && item[recursionKey].length > 0) {
              fn(item[recursionKey]);
            }
          }
        }
      }
    };

    fn(list);
    return result;
  },
  browserType() {
    let userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    let isOpera = userAgent.indexOf("Opera") > -1;
    if (isOpera) {
      // 判断是否Opera浏览器
      return "Opera";
    }
    if (userAgent.indexOf("Firefox") > -1) {
      // 判断是否Firefox浏览器
      return "FF";
    }
    if (userAgent.indexOf("Chrome") > -1) {
      return "Chrome";
    }
    if (userAgent.indexOf("Safari") > -1) {
      // 判断是否Safari浏览器
      return "Safari";
    }
    if (
      userAgent.indexOf("compatible") > -1 &&
      userAgent.indexOf("MSIE") > -1 &&
      !isOpera
    ) {
      // 判断是否IE浏览器
      return "IE";
    }
  },
  downLoadFile(name, data, type = { type: "" }) {
    const blob = new Blob([data], type);
    if ("download" in document.createElement("a")) {
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } else {
      navigator.msSaveBlob(blob, name);
    }
  },
  phoneCheck(phone) {
    return /^1[3456789]\d{9}$/.test(phone);
  },
  /**
   * 计算表格高度
   * @param { dom } outerDom 最外层元素
   * @param  { dom | number } innerDoms 需减掉高度的元素
   * @return { Number } 计算后的高度
   */
  calcTableHeight(outerDom, ...innerDoms) {
    if (!outerDom) return "auto";
    const outerHeight = outerDom.offsetHeight;
    let innerHeightSum = 0;
    let res = 0;
    let innerHeightList = [];
    innerDoms.forEach(item => {
      if (typeof item === "number") {
        innerHeightList.push(item);
      } else {
        innerHeightList.push(item.offsetHeight);
      }
    });
    innerHeightSum = innerHeightList.reduce((a, b) => a + b);
    res = outerHeight - innerHeightSum;
    return res;
  },
  // 获取当前时间戳转换为yyyy-MM-dd
  changeTimeTypeStart(dateTime) {
    var date = dateTime;
    var seperator1 = "-";
    let ms = " 00:00:00";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate + ms;
    return currentdate;
  },
  // 获取当前时间戳转换为yyyy-MM-dd
  changeTimeTypeEnd(dateTime) {
    var date = dateTime;
    var seperator1 = "-";
    let ms = " 23:59:59";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate + ms;
    return currentdate;
  },
  // 上报埋点
  locus(routePath, menuName, type, cryptoJS) {
    let userInfo = JSON.parse(sessionStorage.GLOBAL_IOC).userInfo;
    let account = cryptoJS.decrypt(userInfo.accountEnc);
    let userName = cryptoJS.decrypt(userInfo.nameCnEnc);
    let phone = cryptoJS.decrypt(userInfo.phoneEnc);
    let params = {
      uid: userInfo.uid,
      account,
      userName,
      phone,
      parkCode: localStorage.lay_selectedParkCode, // 园区编码
      periodCode:
        localStorage.lay_selectedParkCode === localStorage.lay_selectedPosCode
          ? ""
          : localStorage.lay_selectedPosCode, // 分期编码
      routeUri: routePath,
      routeName: menuName,
      type, // 0:系统登录, 1:园区切换登录，2:主动登出, 3:菜单页面跳转, 4:按钮页面跳转'
      websession: sessionStorage.IOC_WEB_SESSION,
      pointTime: new Date().getTime()
    };
    api({
      url: "/ioc/security/pointlog/locus",
      method: "post",
      data: params
    })
      .then(() => {})
      .catch(err => {
        console.log("err", err);
      });
  }
};
