import commonUtils from "@/common/utils/common";
const SAFETYMGMT = commonUtils.getRegistry("SAFETYMGMT");

export default {
  // 获取园区incidentNumber查询布控任务产生的未关闭事件详情
  // /incident/queryIncidentOfSuspectId?suspectId=85e6f3b320ff4e5482c4147e53d8029a&objectId=54354352345
  async queryIncidentOfSuspectId(params) {
    let result = await commonUtils.axiosData("/incident/queryIncidentOfSuspectId", "GET", params, SAFETYMGMT);
    return result.data;
  },
  // 获取人员信息
  async getPersonRecords(params) {
    let result = await commonUtils.axiosData("/safecontrol/getSafControlDetailInfo", "GET", params, SAFETYMGMT);
    return result.data;
  },
  // 综合安防等级  状态  类型
  async secutityType(params) {
    let result = await commonUtils.axiosData(
      "/atomiccommon/basVlookup/getAppointTypeDataDir",
      "get",
      params,
      SAFETYMGMT
    );
    return result.data;
  },
  // 人脸追踪通过布控id获取数据接口
  async getFaceTrackingInfo(params) {
    let result = await commonUtils.axiosData(
      `/tracking/v1.0/startPersonnelTracking?suspectId=${params.suspectId}&parkCode=${params.parkCode}&personId=${params.personId}`,
      "get",
      {},
      SAFETYMGMT
    );
    return result.data;
  },
  // 获取车辆详情
  async getStatusCode(params) {
    let result = await commonUtils.axiosData(
      "/atomiccommon/basVlookup/getAppointTypeDataDir?vlookupTypeCode=SAF_TASK_STATUS&enableFlag=true",
      "GET",
      params,
      SAFETYMGMT
    );
    return result.data;
  },
  // 得到黑白名单状态
  async getStatusBlackCode(params) {
    let result = await commonUtils.axiosData(
      "/atomiccommon/basVlookup/getAppointTypeDataDir?vlookupTypeCode=SAF_TASK_TYPE&enableFlag=true",
      "GET",
      params,
      SAFETYMGMT
    );
    return result.data;
  },
  // 删除人脸布控
  async deletePresonData(suspectIds, params) {
    let result = await commonUtils.axiosData(
      `/safecontrol/deleteControl?suspectId=${suspectIds}`,
      "DELETE",
      params,
      SAFETYMGMT
    );
    return result.data;
  },
  // 任务状态切换
  async changeStatus(query, params, ajaxType = "PUT") {
    let result = await commonUtils.axiosData(query, ajaxType, params, SAFETYMGMT);
    return result.data;
  },
  // 获取车辆 和 人员布控列表
  async getSelectControlTask(params) {
    let result = await commonUtils.axiosData(
      "/safecontrol/v1.1/selectControlTask",
      "GET",
      params,
      SAFETYMGMT
    );
    return result.data;
  },
  // 查询告警数量
  async getSelectAlarm(params) {
    let result = await commonUtils.axiosData(
      "/safecontrol/getCountByTaskNumber",
      "GET",
      params,
      SAFETYMGMT
    );
    return result.data;
  },
  // 判断是否能修改布控
  async judgeFaceContron(query, params) {
    let result = await commonUtils.axiosData(
      query,
      "GET",
      params,
      SAFETYMGMT
    );
    return result.data;
  },
  // 迎宾屏&vIP到访具体人脸列表
  async welcomePersonListDetail(urlParams, dataParams) {
    let urlQuery = commonUtils.objectToQueryString(urlParams);
    let result = await commonUtils.axiosData(
      `/file/getSuspectFileList?${urlQuery}`,
      "POST",
      dataParams,
      SAFETYMGMT
    );
    return result.data;
  }
};