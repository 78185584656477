export default [
  {
    path: "/global",
    name: "GlobalEvent",
    redirect: "/global/event-center",
    component: () =>
      import(
        /* webpackChunkName: "GlobalEvent" */ "@/pages/securityEventCenter/securityEventCenter"
      ),
    children: [
      {
        // 全局事件中心
        path: "event-center",
        name: "GlobalEventCenter",
        component: () =>
          import(
            /* webpackChunkName: "GlobalEventCenter" */ "@/pages/securityEventCenter/pages/eventList"
          )
      },
      {
        // 事件详情
        path: "event-center/securityEventDetail",
        name: "GlobalSecurityEventDetail",
        component: () =>
          import(
            /* webpackChunkName: "securityEventDetail" */ "@/pages/securityEventCenter/pages/securityEventDetail"
          )
      },
      {
        // 全局事件列表
        path: "event-list",
        name: "GlobalEventList",
        component: () =>
          // eslint-disable-next-line
          (isDanTianPark && isDanTianPark(localStorage.getItem("lay_selectedPosCode")))
            ? import(
              /* webpackChunkName: "GlobalEventListDT" */ "@/pages/securityEventFilesDT/securityEventFiles"
            )
            : import(
              /* webpackChunkName: "GlobalEventList" */ "@/pages/securityEventFiles/securityEventFiles"
            )
      },
      {
        // 全局任务工单
        path: "task-order",
        name: "GlobalTaskOrder",
        component: () =>
          import(
            /* webpackChunkName: "GlobalTaskOrder" */ "@/pages/securityTaskManagement/index"
          )
      },
      {
        path: "task-order/securityTaskManagementDatails/:id",
        name: "TaskOrderSecurityTaskManagementDatails",
        component: () =>
          import(
            /* webpackChunkName: "taskOrderSecurityTaskManagementDatails" */ "@/pages/globalSecurity/securityTaskManagementDatails"
          )
      }
    ]
  }
];
