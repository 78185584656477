import Vue from "vue";
import Vuex from "vuex";

import defaultState from "./app-state.js";
import getters from "./app-getters.js";
import mutations from "./app-mutations.js";
import actions from "./app-actions.js";
import plugins from "./app-plugins.js";
import equipInfo from "./modules/eventCenter/equipInfo";
import baseUser from "./modules/base-user";
import basePosition from "./modules/base-position";
import header from "./modules/header";
import navigation from "./modules/navigation/navigation";
import mutationscard from "./modules/statistics/stateCardMutation";
import stateMapColRowPopup from "./modules/statistics/stateMapColRowPopup";
import security from "./modules/globalSecurity/globalMap/security";
import ocxCutVideo from "./modules/globalSecurity/ocxCutVideo";
import perimeterInfo from "./modules/globalSecurity/perimeterInfo";
import dailyPatrol from "./modules/globalSecurity/dailyPatrol";
import liveDailyPatrol from "./modules/globalSecurity/liveDailyPatrol";
import faceTracking from "./modules/globalSecurity/faceTracking";
import personPopup from "./modules/personPopup/personPopup";
import mapZoomInfoConfig from "./modules/mapZoomInfoConfig/mapZoomInfoConfig";
import publicCloudVideo from "./modules/publicCloudVideo/publicCloudVideo";
import videoPatroPlan from "./modules/publicCloudVideo/videoPatroPlan";
import securityStatus from "./modules/globalSecurity/securityStatus";
import globalOvierview from "./modules/globalOverview/globalOvierview";
import faceControlDetails from "./modules/globalSecurity/faceControlDetails";
import faceSearchPage from "./modules/staffManage/faceSearchPage";
import personLabel from "./modules/staffManage/personLabel"; // 人员标注
import visitorManagement from "./modules/staffManage/visitorManagement.js";
import visitorStatistics from "./modules/staffManage/visitorStatistics.js";
import personStatisticPage from "./modules/staffManage/personStatisticPage"; // 人员统计
import patrolPreview from "./modules/globalSecurity/patrolPreview"; // 视频巡逻预览

Vue.use(Vuex);

export default new Vuex.Store({
  state: defaultState,
  getters,
  mutations,
  actions,
  plugins,
  modules: {
    baseUser,
    basePosition,
    header,
    navigation,
    mutationscard,
    stateMapColRowPopup,
    security,
    ocxCutVideo,
    dailyPatrol,
    liveDailyPatrol,
    faceTracking,
    personPopup,
    mapZoomInfoConfig,
    publicCloudVideo,
    perimeterInfo,
    globalOvierview,
    securityStatus,
    faceControlDetails,
    videoPatroPlan,
    faceSearchPage,
    visitorManagement,
    visitorStatistics,
    personStatisticPage,
    personLabel,
    patrolPreview,
    equipInfo
  }
});
