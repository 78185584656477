import commonAjax from "@/common/utils/common";
import Ajax from "@/js/api.js";
import { RES_CODE_SUCCESS } from "@/common/utils/constant";
import beforePic from "@/assets/statistic/borken2.png";
import thisPic from "@/assets/statistic/borken.png";
// const DATACENTERSELFSERVICE = Ajax.getRegistry("DATACENTERSELFSERVICE");
const SAFETYMGMT = commonAjax.getRegistry("SAFETYMGMT");
export default {
  namespaced: true,
  state: {
    recentWeekData: {
      recordDate: [],
      passNum: []
    },
    recentMonthData: {
      recordDate: [],
      passNum: []
    },
    ratioData: {},
    allPersonData: {},
    treeData: [],
    twoWeekData: {
      nowWeek: [],
      lastWeek: [],
      weekDay: []
    },
    rankData: [],
    allBarData: {
      blacklist: [],
      jobList: [],
      otherList: [],
      visitsList: [],
      personTotalList: []
    }
  },
  getters: {
    // 获取园区概览数据
    viewData(state) {
      let data = state.allPersonData;
      return [
        {name: "园区总人数", isLine: true, color: "#275ff4", today: data.todayParkTotalNum || 0, yesterday: data.yesterdayParkTotalNum || 0},
        {name: "企业员工", isLine: true, color: "#0095da", today: data.todayEmployeesNum || 0, yesterday: data.yesterdayEmployeesNum || 0},
        {name: "访客", isLine: false, color: "#a05ceb", today: data.todayVisitorNum || 0, yesterday: data.yesterdayVisitorNum || 0},
        {name: "黑名单", isLine: true, color: "#3dd9d9", today: data.todayBlacklistNum || 0, yesterday: data.yesterdayBlacklistNum || 0},
        {name: "其他", isLine: true, color: "#339967", today: data.todayOtherNum || 0, yesterday: data.yesterdayOtherNum || 0}
      ];
    },
    // 排名数据
    handleRankData(state) {
      let allNum = state.rankData.length && state.rankData[0].personNum;
      let arr = state.rankData.map((item) => {
        let percent = Math.round((item.personNum / allNum) * 100);
        return {
          ...item,
          percent
        };
      });

      return arr;
    },
    // 当天人流数据
    currentPersonData(state) {
      let x = state && state.allBarData && state.allBarData.otherList.map(item => {
        let time = item.startTime + "~" + item.endTime;
        return time;
      });
      let blacKData = state && state.allBarData.blacklist && state.allBarData.blacklist.map((item, index) => {
        return {
          value: item.personNum,
          color: "#3dd9d9"
        };
      });
      let visitData = state && state.allBarData.visitsList && state.allBarData.visitsList.map((item, index) => {
        return {
          value: item.personNum,
          color: "#a05ceb"
        };
      });
      let companyData = state && state.allBarData.jobList && state.allBarData.jobList.map((item, index) => {
        return {
          value: item.personNum,
          color: "#0095da"
        };
      });
      let otherData = state && state.allBarData.otherList && state.allBarData.otherList.map((item) => {
        return {
          value: item.personNum,
          color: "#339967"
        };
      });
      let totalData = state && state.allBarData.personTotalList && state.allBarData.personTotalList.map((item) => {
        return {
          value: item,
          icon: thisPic
        };
      });
      let formatter = params => {
        let title = "";
        let tipHtml = "";
        for (let i in params) {
          title = params[i].axisValue;
          if (params[i].data.icon) {
            tipHtml = tipHtml + `<div style="color:#8091ac;">
          <img src="${params[i].data.icon}" style="width:14px;height:14px;display:inline-block;margin-right:6px;"/>
            <span style="margin-right:6px;display:inline-block;width:70px">${params[i].seriesName}</span><span style="color:#fff;">${params[i].value}</span>人</div>`;
          } else {
            tipHtml = tipHtml + `<div style="color:#8091ac;">
          <span style="width:14px;height:14px;position:relative;top:2px;background:${params[i].data.color};border-radius:4px;margin-right:6px;display:inline-block"></span>
            <span style="margin-right:6px;display:inline-block;width:70px">${params[i].seriesName}</span><span style="color:#fff;">${params[i].value}</span>人</div>`;
          }
        }
        return `<span style="color:#fff;font-size:16px;">${title}</span>${tipHtml}`;
      };
      let legend = {
        padding: 0,
        top: 0,
        left: 20,
        itemWidth: 14,
        itemHeight: 14,
        borderRadius: 2,
        data: [
          {
            name: "访客",
            textStyle: {
              color: "#8091ac"
            }
          },
          {
            name: "企业员工",
            textStyle: {
              color: "#8091ac"
            }
          },
          {
            name: "黑名单",
            textStyle: {
              color: "#8091ac"
            }
          },
          {
            name: "其他",
            textStyle: {
              color: "#8091ac"
            }
          },
          {
            name: "总数",
            icon: `image://${thisPic}`,
            textStyle: {
              color: "#8091ac"
            }
          }
        ]
      };
      let y = [
        {
          name: "访客",
          type: "bar",
          data: visitData,
          stack: "a",
          itemStyle: {
            color: "#a05ceb"
          }
          // circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
        },
        {
          name: "企业员工",
          type: "bar",
          stack: "a",
          data: companyData,
          itemStyle: {
            color: "#0095da"
          }
        },
        {
          name: "黑名单",
          type: "bar",
          stack: "a",
          data: blacKData,
          itemStyle: {
            color: "#3dd9d9"
          }
        },
        {
          name: "其他",
          type: "bar",
          stack: "a",
          data: otherData,
          itemStyle: {
            color: "#339967"
          }
        },
        {
          name: "总数",
          type: "line",
          symbol: "circle",
          data: totalData,
          itemStyle: {
            color: "#275ff4"
          }
        }
      ];
      return {x, y, legend, formatter};
    },
    // 周月的数据
    weekXyData(state) {
      let x = state.recentWeekData.recordDate;
      let ydata = state.recentWeekData && state.recentWeekData.passNum && state.recentWeekData.passNum.map(item => {
        return {
          value: item,
          color: "#1b56e6",
          name: item.name || ""
        };
      });

      let formatter = params => {
        let title = "";
        let tipHtml = "";
        for (let i in params) {
          let time = params[i].axisValue.split("~");
          let before = time[0].split("/");
          let last = time[1].split("/");
          title = `${before[0]}月${before[1]}日~${last[0]}月${last[1]}日`;
          tipHtml = tipHtml + `<div style="color:#8091ac;">
          <span style="width:14px;height:14px;position:relative;top:2px;background-color:${params[i].data.color};border-radius:4px;margin-right:6px;display:inline-block"></span>
            <span style="margin-right:6px">${params[i].seriesName}</span><span style="color:#fff;">${params[i].value}</span>人</div>`;
        }
        return `<span style="color:#fff;font-size:16px;">${title}</span>${tipHtml}`;
      };
      let y = [{
        name: "人员数",
        type: "bar",
        barWidth: "30%",
        data: ydata,
        itemStyle: {
          color: "#1b56e6"
        }
      }];
      return {x, y, formatter};
    },
    monthXyData(state) {
      let x = state.recentMonthData.recordDate;
      let ydata = state.recentMonthData && state.recentMonthData.passNum && state.recentMonthData.passNum.map(item => {
        return {
          value: item,
          color: "#1b56e6",
          name: item.name || ""
        };
      });

      let formatter = params => {
        let title = "";
        let tipHtml = "";
        for (let i in params) {
          let time = params[i].axisValue.split("/");
          title = `${time[0]}年${time[1]}月`;
          tipHtml = tipHtml + `<div style="color:#8091ac;">
          <span style="width:14px;height:14px;position:relative;top:2px;background-color:${params[i].data.color};border-radius:4px;margin-right:6px;display:inline-block"></span>
            <span style="margin-right:6px">${params[i].seriesName}</span><span style="color:#fff;">${params[i].value}</span>人</div>`;
        }
        return `<span style="color:#fff;font-size:16px;">${title}</span>${tipHtml}`;
      };
      let y = [{
        name: "人员数",
        type: "bar",
        barWidth: "30%",
        data: ydata,
        itemStyle: {
          color: "#1b56e6"
        }
      }];
      return {x, y, formatter};
    },
    // 近两周的数据compare-chart数据
    compareData(state) {
      let x = state && state.twoWeekData && state.twoWeekData.weekDay;
      let nowData = state && state.twoWeekData && state.twoWeekData.nowWeek.map((item, index) => {
        return {
          name: `本${x[index]}`,
          value: item.personNum,
          date: item.currentDate,
          icon: thisPic
        };
      });
      let lastData = state && state.twoWeekData && state.twoWeekData.lastWeek.map((item, index) => {
        return {
          name: `上${x[index]}`,
          value: item.lastPersonNum,
          date: item.lastDate,
          icon: beforePic
        };
      });
      let tooltip = {
        trigger: "axis",
        formatter: (params) => {
          let tipHtml = "";
          for (let i in params) {
            tipHtml = tipHtml + `<div style="color:#8091ac;"><img style="width:14px;margin-right:6px" src="${params[i].data.icon}">
            <span style="margin-right:6px">${params[i].data.name}</span>
            <span style="margin-right:6px">${params[i].data.date}</span>
            <span style="color:#fff;">${params[i].data.value}</span>人</div>`;
          }
          return tipHtml;
        },
        axisPointer: {
          type: "line",
          lineStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: "#1a56e5" // 0% 处的颜色
              }, {
                offset: 1, color: "#388ff4" // 100% 处的颜色
              }]
            }
          }
        }
      };
      let legend = {
        padding: 0,
        top: 0,
        left: 20,
        itemWidth: 14,
        itemHeight: 14,
        borderRadius: 2,
        data: [
          {
            name: "本周",
            icon: `image://${thisPic}`,
            textStyle: {
              color: "#8091ac",
              fontSize: 12
            }
          },
          {
            name: "上周",
            icon: `image://${beforePic}`,
            textStyle: {
              color: "#8091ac"
            }
          }
        ]
      };
      let y = [
        {
          name: "本周",
          type: "line",
          data: nowData,
          symbol: "circle",
          itemStyle: {
            color: "#275ff4"
          },
          // circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: "rgba(39, 95, 244, 1)"
              },
              {
                offset: 1, color: "rgba(39, 95, 244, 0.1)"
              }]
            },
            opacity: 0.6
          }
        },
        {
          name: "上周",
          type: "line",
          data: lastData,
          symbol: "circle",
          itemStyle: {
            color: "#0095da"
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: "rgba(0,149,218, 1)"
              },
              {
                offset: 1, color: "rgba(0,149,218, 0.1)"
              }]
            },
            opacity: 0.6
          }
        }
      ];
      return {x, y, legend, tooltip};
    },
    // 园区circle数据
    circleData(state) {
      let circleData = state.ratioData;
      return [
        {name: "企业员工", value: circleData.employeesNum || 0, rate: `${circleData.employeesRate || 0}%`},
        {name: "访客", value: circleData.visitorNum || 0, rate: `${circleData.visitorRate || 0}%`},
        {name: "黑名单", value: circleData.blacklistNum || 0, rate: `${circleData.blacklistRate || 0}%`},
        {name: "其他", value: circleData.otherNum || 0, rate: `${circleData.otherRate || 0}%`}
      ];
    },
    transObj(state) {
      let circleData = state.ratioData;
      // 饼图传值
      return {
        totalNum: circleData.pTotal || 0,
        formatter: "{b}<br />{c}人",
        legend: [
          {
            orient: "horizontal",
            x: "5%",
            y: "60%",
            itemWidth: 14, // 设置图例图形的宽
            itemHeight: 14,
            data: ["企业员工"]
          },
          {
            orient: "horizontal",
            x: "50%",
            y: "60%",
            itemWidth: 14, // 设置图例图形的宽
            itemHeight: 14,
            data: ["访客"]
          },
          {
            orient: "horizontal",
            x: "5%",
            y: "75%",
            itemWidth: 14, // 设置图例图形的宽
            itemHeight: 14,
            data: ["黑名单"]
          },
          {
            orient: "horizontal",
            x: "50%",
            y: "75%",
            itemWidth: 14, // 设置图例图形的宽
            itemHeight: 14,
            data: ["其他"]
          }
        ],
        series: {
          name: "访问来源",
          center: ["50%", "30%"], // 调整位置
          radius: ["40%", "50%"], // 调整大小
          color: ["#0095da", "#a05ceb", "#3dd9d9", "#339967"],
          data: [
            {name: "企业员工", value: circleData.employeesNum},
            {name: "访客", value: circleData.visitorNum},
            {name: "黑名单", value: circleData.blacklistNum},
            {name: "其他", value: circleData.otherNum}
          ]
        }
      };
    }
  },
  mutations: {
    getRatioFunc(state, data) {
      state.ratioData = data;
    },
    getAllPersonFunc(state, data) {
      state.allPersonData = data;
    },
    getRecentMonthFunc(state, data) {
      state.recentMonthData = data;
    },
    getRecentWeekFunc(state, data) {
      state.recentWeekData = data;
    },
    getTwoWeekFunc(state, data) {
      state.twoWeekData = data;
    },
    getLeftTreeFunc(state, {data}) {
      state.treeData = data.parkVo;
    },
    getRankFunc(state, data) {
      state.rankData = data;
    },
    getAllBarFunc(state, data) {
      state.allBarData = data;
    }
  },
  actions: {
    // 获取左侧树结构
    getLeftTreeData({commit}, {path, params}) {
      return commonAjax.axiosData(path, "GET", params, SAFETYMGMT).then(
        ({data}) => {
          if (data.code === RES_CODE_SUCCESS) {
            commit("getLeftTreeFunc", data);
          }
          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    // 获取园区占比数据
    getRatioData({commit}, {path, params}) {
      return Ajax({
        url: path,
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (data) => {
          commit("getRatioFunc", data);
          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    // 获取园区总览数据
    getAllPersonData({commit}, {path, params}) {
      return Ajax({
        url: path,
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (data) => {
          commit("getAllPersonFunc", data);

          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    // 最近12月
    getRecentMonthData({commit}, {path, params}) {
      return Ajax({
        url: path,
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (data) => {
          commit("getRecentMonthFunc", data);

          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    // 最近12周
    getRecentWeekData({commit}, {path, params}) {
      return Ajax({
        url: path,
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (data) => {
          commit("getRecentWeekFunc", data);

          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    // 近两周对比数据
    getTwoWeekData({commit}, {path, params}) {
      return Ajax({
        url: path,
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (data) => {
          commit("getTwoWeekFunc", data);

          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    // 排名数据
    getRankData({commit}, {path, params}) {
      return Ajax({
        url: path,
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (data) => {
          commit("getRankFunc", data);

          return false;
        }
      ).catch(() => {
        return false;
      });
    },
    // 园区最近12人流图
    getAllBarData({commit}, {path, params}) {
      return Ajax({
        url: path,
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (data) => {
          commit("getAllBarFunc", data);

          return false;
        }
      ).catch(() => {
        return false;
      });
    }
  }
};
