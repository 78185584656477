import commonUtils from "@/common/utils/common";

const LOCATION = commonUtils.getRegistry("BASEMGMT");
const USER_MANGER = commonUtils.getRegistry("SYSROLES");
const BASEURL = commonUtils.getRegistry("SAFETYMGMT");
const SYSROLES = commonUtils.getRegistry("SYSROLES");

// 地理位置 SECUTITY_TYPE_URL
export default {
  // 导航条位置查询区域子级目录
  async findchlidNodesList(params) {
    let result = await commonUtils.axiosData("/basParkLocation/findchlidNodesList", "GET", params, LOCATION);
    let resultObj = {
      list: []
    };
    // 经纬度为空值的去掉。
    if (result.data.data) {
      for (let sub of result.data.data) {
        if (sub.latitude && sub.longitude) {
          resultObj.list.push(sub);
        }
      }
    }
    return resultObj;
  },
  // 查询区域树形列表
  async findLocationTreeList(params) {
    let result = await commonUtils.axiosData("/park/actions/listUserPark", "GET", params, USER_MANGER);
    return result.data;
  },
  // 退出登陆
  async logOut() {
    let result = await commonUtils.axiosData("/ioc/gateway/logout");
    return result.data;
  },
  async getUserInfo(params) {
    let result = await commonUtils.axiosData(
      // "/sysusers/findUserInfo",
      "/sysusers/currentUserInfo",
      "GET",
      params,
      USER_MANGER
    );
    return Promise.resolve(result.data);
    // return result.data;
  },
  // 获取按钮权限列表
  async getButtonsPermList(params) {
    let result = await commonUtils.axiosData("/auth/islogin", "GET", params, SYSROLES);
    return result.data;
  },
  // 根据园区获取ocx的登陆信息
  async getOcxLoginInfo(params) {
    let result = await commonUtils.axiosData(`/atomiccommon/vcnconfig/actions/findVcnConfig?parkCode=${params}`, "GET", "", BASEURL);
    return result.data;
  },
  // 修改密码
  async changePassWord(params) {
    const reuslt = await commonUtils.axiosData(
      "/cloud/modifypassword",
      "POST",
      params,
      USER_MANGER
    );
    return reuslt.data;
  }
};
