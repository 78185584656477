import Ajax from "@/js/api.js";
export default {
  namespaced: true,
  state: {
    parkData: [], // 园区统计总数
    companyTop: [], // 园区top10数据
    VisitorData: [], // 园区访客统计
    companyData: [], // 公司统计总数
    companyVisitData: [] // 公司访客总数
  },
  getters: {

  },
  mutations: {
    parkTreeData(state, data) {
      state.parkData = data;
    },
    getCompanyRanking(state, data) {
      state.companyTop = data;
    },
    VisitorTotal(state, data) {
      state.VisitorData = data;
    },
    companyDataTotal(state, data) {
      state.companyData = data;
    },
    companyVisitorData(state, data) {
      state.companyVisitData = data;
    }
  },
  actions: {
    // 获取园区历史数据
    getData({ commit }, params) {
      Ajax({
        url: "/datacenter/api/datacenter/V2.0/getParkTotalVisitorNum",
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (data) => {
          commit("parkTreeData", data);
        });
    },
    // 获取园区公司top10
    getCompanyRanking({ commit }, params) {
      Ajax({
        url: "/datacenter/api/datacenter/V2.0/getCompVisitorNumRanking",
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (
          data
        ) => {
          commit("getCompanyRanking", data);
        });
    },
    // 获取园区访客统计
    parkVisitorTotal({ commit }, params) {
      Ajax({
        url: "/datacenter/api/datacenter/V2.0/getParkVisitorNum",
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (
          data
        ) => {
          commit("VisitorTotal", data);
        });
    },
    // 获取公司历史总数
    getCompanyData({ commit }, params) {
      Ajax({
        url: "/datacenter/api/datacenter/V2.0/getCompTotalVisitorNum",
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (
          data
        ) => {
          commit("companyDataTotal", data);
        });
    },
    // 获取公司访客统计
    getCompanyvisitor({ commit }, params) {
      Ajax({
        url: "/datacenter/api/datacenter/V2.0/getCompVisitorNum",
        method: "post",
        data: params,
        headers: {
          "websession": "f5227839c39a4b243c8720ac774f1dff"
        }
      }, { showLoading: false }).then(
        (
          data
        ) => {
          commit("companyVisitorData", data);
        });
    }
  }
};