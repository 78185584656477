import api from "@/js/api.js";
import commonUtils from "@/common/utils/common";
import {
  RES_CODE_SUCCESS
} from "@/common/utils/constant";
import { iotAdminURL } from "@/common/registry/sysRegistry";
export default {
  login({commit}, { userId, password, accountType }) {
    return new Promise((resolve) => {
      api({
        url: "/ioc/security/v2/authority/auth/token",
        method: "post",
        data: {
          userId,
          password,
          accountType,
          tokenType: "password",
          clientId: 1
        }
      }, {addPositionParams: false}).then(({ accessToken }) => {
        sessionStorage.setItem("IOC_WEB_SESSION", accessToken);
        resolve({accessToken});
      });
    });
  },
  async getQRCode({commit}, params) {
    let result = await commonUtils.axiosData(
      "/platformservice/login/getQRCode",
      "POST",
      params,
      iotAdminURL.uatURL
    );
    return result.data;
  },
  async destroyQRCode({commit}, params) {
    let result = await commonUtils.axiosData(
      "/platformservice/login/destroyQRCode",
      "POST",
      params,
      iotAdminURL.uatURL
    );
    return result.data;
  },
  // 检查二维码是否有效 GET /qrcode/check/{sign}
  async checkQRCode({commit}, sign) {
    let result = await commonUtils.axiosData(
      `/ioc/qrcode/check/${sign}`,
      "GET",
      null
    );
    if (result.data.code === RES_CODE_SUCCESS) {
      let { accessToken, corporationVO, userPerm } = result.data.data;
      sessionStorage.setItem("IOC_WEB_SESSION", accessToken);
      let {menuList = [], routeMap = null, ...userInfo} = userPerm;
      userInfo = {
        ...userInfo,
        ...corporationVO
      };
      commit("fillLeftNav", menuList);
      commit("fillUser", userInfo);
      commit("module_fillUser", userInfo);

      let leafNodePosCodeArr = [];

      // let parkListArr = !parkList ? [] : UtilFn.fillIndexToRecursionData([parkList], {
      //   handleItem(item) {
      //     item.label = item.posCn;
      //     return item;
      //   },
      //   fillIndexKey: "value",
      //   handleIndex(indexArr) {
      //     return indexArr.join("-");
      //   },
      //   handLeafNode(item) {
      //     item.posCode && leafNodePosCodeArr.push(item.posCode);
      //     return item;
      //   }
      // });
      commit("module_fillAllPosLeafnodeCodeArr", leafNodePosCodeArr);

      commit("fillBtnAuth", routeMap);
    }
    return result.data;
  }
};