/**
 * @desc webSocket方法
 * @param {url,onmessage,onopen,onerror,onclose} Object
 * @param url string required websocket地址
 * @param onmessage Function required 消息推送的回调
 * @param onopen Function 开启后的回调
 * @param onerror Function 错误时的回调
 * @param onclose Function 关闭后的回调
 * @method close() 断开websocket链接
 * @method readyState() 返回当前websocket状态 1为正常
 * @exception 实例的对外仅暴露close() & readyState() 2个方法
 * @example 示例代码
 * import { Sockets } from "xx/socket-utils.js"
 * const socketVar = new Sockets({
 *   url: "", 必传
 *   onmessage: evt => {
 *      // onmessage为回调函数，必传，evt为推送信息
 *      // todo...
 *   },
 *   onopen: evt => {开启回调，非必传},
 *   onerror: evt => {异常回调，非必传},
 *   onclose: evt => {关闭回调，非必传}
 * })
 * // 暴露2个方法 readyState & close
 * socketVar.close(); // 断开连接
 * socketVar.readyState(); // 返回当前websocket状态, 1为正常
 */
export class Sockets {
  constructor(opt) {
    const typeCheck = param => Object.prototype.toString.call(param);
    const {
      url = null,
      onmessage = null,
      onopen = (event) => {},
      onerror = () => {},
      onclose = (event) => {}
    } = opt;
    if (url && typeof url === "string" && onmessage && typeof onmessage === "function") {
      this._socket = new WebSocket(url);
      this._onopen(onopen);
      this._onerror(onerror);
      this._onclose(onclose);
      this._socket.onmessage = event => {
        onmessage(event);
      };
      this.readyState = this.readyState.bind(this);
      this.close = this.close.bind(this);
    } else {
      throw new Error(`socket参数错误,url应为string,实为${typeCheck(url)},onmessage应为fucntion,实为${typeCheck(onmessage)}`);
    }
  }
  // 连接状态
  readyState() {
    return this._socket.readyState || "1";
  };
  // 开启连接
  _onopen(fn) {
    this._socket.onopen = event => {
      typeof fn === "function" && fn(event);
    };
  }
  // 关闭连接
  _onclose(fn) {
    this._socket.onclose = event => {
      typeof fn === "function" && fn(event);
    };
  }
  // 连接异常
  _onerror(fn) {
    this._socket.onerror = () => {
      typeof fn === "function" && fn();
    };
  }
  // 断开链接
  close() {
    this._socket.close();
  }
}