import singleSpaVue from "single-spa-vue";

import "@/init.js";
import { vueI18n } from "./I18N.js";
import store from "./store";
import App from "./App";
import router from "./router";

import Vue from "vue";
// import mysip from "./js/mysip";
// Vue.prototype.mysip = mysip;
// 链接的示例;
// const configuration = {
//   // 当前项目名称和环境，用于后台根据客户端区分来源
//   client: "测试项目-dev",
//   // 联系人标头，固定格式
//   contact_uri: "sip:88180410000378@api-ihwstg.vanrui.com:5068transport=wss",
//   // 用户名，拨打电话时显示的名称
//   display_name: "user",
//   // sip密码   password: 'password',
//   // sipcode和ws组成的uri，固定格式，
//   uri: "sip:88180410000378@api-ihw-stg.vanrui.com:5068"
//   // websocket地址   ws: 'wss://api-ihw-stg.vanrui.com:5068',
// };
// const sipUA = mysip(configuration);
const laySelectedPosCode = localStorage.getItem("lay_selectedPosCode");
// eslint-disable-next-line
if (isDanTianPark && isDanTianPark(laySelectedPosCode)) {
  let scriptEvent = document.createElement("script");
  scriptEvent.type = "text/javascript";
  scriptEvent.src = `/js/dantian/${laySelectedPosCode}/eventData1.js`;
  document.getElementById("indexHead").appendChild(scriptEvent);
}

// 监控代码开始
if (window.TINGYUN && window.TINGYUN.wrapVueRouter) {
  window.TINGYUN.wrapVueRouter(router, {
    endDelay: 500 // 切换路由后延迟结束时间
  });
}
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    store,
    i18n: vueI18n
  }
});
if (window.isPosCodeWuHan) {
  let mapList = JSON.parse(sessionStorage.getItem("resetGistype"))["parkConfigList"]["mapConfig"];
  if (mapList) {
    if (mapList.indexOf("vrbim") > -1) {
      sessionStorage.setItem("gisType", "vrbim");
    } else {
      if (mapList.indexOf("gis") > -1) {
        sessionStorage.setItem("gisType", "gis");
      } else {
        sessionStorage.setItem("gisType", "gaode");
      }
    }
  }
}

// 打上gitcommit信息
if (!window.$WANRUI) {
  window.$WANRUI = {};
}
window.$WANRUI.IOCSECURITYGIS_GITCOMMIT = process.env.VUE_APP_GITCOMMIT;
window.$WANRUI.IOCSECURITYGIS_GITADDRESS = "https://git.vankeservice.com/ioc/ioc-security-gis.git";

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
