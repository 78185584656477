<template>
  <!-- mainWrapper -->
  <div class="wiew-websocket">
    <!-- 事件消息推送 -->
    <transition name="slideRight" mode="out-in">
      <div
        class="eventMessageOpen"
        v-show="eventMessagePushData.length > 0 && !isShowEvt"
      >
        <div class="openControl">
          <iframe
            class="iframeBox"
            src="about:blank"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            style="
              top: 0;
              left: 0;
              position: absolute;
              visibility: inherit;
              width: 100%;
              height: 100%;
              z-index: -1;
              filter: alpha(opacity=0);
            "
          ></iframe>
          <div class="openMessage" @click="openEventMessage(0)">
            <i class="iconfont icon-zhankai"></i>
          </div>
        </div>
      </div>
    </transition>
    <!-- 能耗推送 -->
    <transition name="slideRight" mode="out-in">
      <div
        class="eventMessageOpen"
        v-show="eneryMessagePushData.length > 0 && !isEneryShow"
      >
        <div class="openControl">
          <iframe
            class="iframeBox"
            src="about:blank"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            style="
              top: 0;
              left: 0;
              position: absolute;
              visibility: inherit;
              width: 100%;
              height: 100%;
              z-index: -1;
              filter: alpha(opacity=0);
            "
          ></iframe>
          <div class="openMessage" @click="openEventMessage(2)">
            <i class="iconfont icon-zhankai"></i>
          </div>
        </div>
      </div>
    </transition>
    <transition name="slideRight" mode="out-in">
      <div
        class="eventMessagePush"
        v-show="eneryMessagePushData.length > 0 && isEneryShow"
      >
        <div class="pushControl">
          <iframe
            class="iframeBox"
            src="about:blank"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            style="
              top: 0;
              left: 0;
              position: absolute;
              visibility: inherit;
              width: 100%;
              height: 100%;
              z-index: -1;
              filter: alpha(opacity=0);
            "
          ></iframe>
          <div class="retractMessage" @click="closeAllEventMessage(2)">
            <i class="iconfont icon-guanbi"></i>
          </div>
          <div class="retractMessage" @click="retractEventMessage(2)">
            <i class="iconfont icon-shouqi"></i>
          </div>
        </div>
        <div
          class="messagePushItem"
          :class="item.animationClass"
          v-for="(item, index) in eneryMessagePushData"
          :key="index"
        >
          <iframe
            class="iframeBox"
            src="about:blank"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            style="
              top: 0;
              left: 0;
              position: absolute;
              visibility: inherit;
              width: 100%;
              height: 100%;
              z-index: -1;
              filter: alpha(opacity=0);
            "
          ></iframe>
          <div
            class="iconfont icon-guanbi"
            @click="closeEneryWebSocket(index)"
          ></div>
          <div class="messagePushTitle">
            {{ item.time }}&nbsp;&nbsp;{{ item.faultName }}
          </div>
          <div
            class="messagePushSee"
            @click="closeEneryWebSocket(index, item.router)"
          ></div>
          <div class="messagePushSee">
            <router-link
              :to="{
                path: item.router,
                query: {
                  faultId: item.faultId,
                  time: item.time,
                  deviceId: item.deviceId,
                },
              }"
              >{{ $t("security_66_gis") }}</router-link
            >
          </div>
        </div>
      </div>
    </transition>
    <!-- 事件推送 -->
    <transition name="slideRight" mode="out-in">
      <div
        class="eventMessagePush"
        v-show="eventMessagePushData.length > 0 && isShowEvt"
      >
        <div class="pushControl">
          <iframe
            class="iframeBox"
            src="about:blank"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            style="
              top: 0;
              left: 0;
              position: absolute;
              visibility: inherit;
              width: 100%;
              height: 100%;
              z-index: -1;
              filter: alpha(opacity=0);
            "
          ></iframe>
          <div class="retractMessage" @click="closeAllEventMessage(0)">
            <i class="iconfont icon-guanbi"></i>
          </div>
          <div class="retractMessage" @click="retractEventMessage(0)">
            <i class="iconfont icon-shouqi"></i>
          </div>
        </div>
        <div
          class="messagePushItem"
          :class="item.animationClass"
          v-for="(item, index) in eventMessagePushData"
          :key="index"
        >
          <iframe
            class="iframeBox"
            src="about:blank"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            style="
              top: 0;
              left: 0;
              position: absolute;
              visibility: inherit;
              width: 100%;
              height: 100%;
              z-index: -1;
              filter: alpha(opacity=0);
            "
          ></iframe>
          <div
            class="iconfont icon-guanbi"
            @click="closeWebSocket(index)"
          ></div>
          <div class="messagePushTitle">
            {{ $t("security_65_gis") }}({{ item.openDate }})&nbsp;&nbsp;{{
              item.incidentTitle
            }}
          </div>
          <div
            class="messagePushSee"
            @click="closeWebSocket(index, item.router)"
          >
            <router-link
              v-if="item.type === 4"
              @click.native="
                routerPush(`${item.router}?parkCode=${item.parkCode}`)
              "
              to=""
              >{{ $t("security_66_gis") }}</router-link
            >
            <!-- <a v-else-if="item.type===0&&item.subtypeCode==='PUBLIC_BLACK_LIST'" @click="faceTracking(item.suspectId, item.parkCode, item.objectId)" href="javascript: ;" style="color:#3a7aea">立即查看</a> -->
            <router-link
              v-else-if="item.type === 0"
              @click.native="
                routerPush(
                  `${item.router}/securityEventDetail?incidentNumber=${item.incidentNumber}`
                )
              "
              to=""
              >{{ $t("security_66_gis") }}</router-link
            >
            <!-- <router-link v-else @click.native="routerPush(`${item.router}/${item.incidentNumber}`)" to="" >{{ $t('security_66_gis') }}</router-link> -->
            <router-link
              v-else
              :to="{
                path: `${item.router}`,
                query: { incidentNumber: item.incidentNumber },
              }"
              >{{ $t("security_66_gis") }}</router-link
            >
          </div>
        </div>
      </div>
    </transition>
    <!-- 安防事件中心——门禁最小化 关闭 -->
    <transition name="slideRight" mode="out-in">
      <div
        class="doorMessageOpen"
        v-show="doorMessagePushData.length > 0 && !isShowDoor"
      >
        <div class="openControl">
          <iframe
            class="iframeBox"
            src="about:blank"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            style="
              top: 0;
              left: 0;
              position: absolute;
              visibility: inherit;
              width: 100%;
              height: 100%;
              z-index: -1;
              filter: alpha(opacity=0);
            "
          ></iframe>
          <div class="openMessage" @click="openEventMessage(1)">
            <i class="iconfont icon-zhankai"></i>
          </div>
        </div>
      </div>
    </transition>
    <!-- 安防事件中心——门禁消息推送 -->
    <transition name="slideRight" mode="out-in">
      <div
        class="doorMessagePush"
        v-show="doorMessagePushData.length > 0 && isShowDoor"
      >
        <div class="pushControl">
          <iframe
            class="iframeBox"
            src="about:blank"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            style="
              top: 0;
              left: 0;
              position: absolute;
              visibility: inherit;
              width: 100%;
              height: 100%;
              z-index: -1;
              filter: alpha(opacity=0);
            "
          ></iframe>
          <div class="retractMessage" @click="closeAllEventMessage(1)">
            <i class="iconfont icon-guanbi"></i>
          </div>
          <div class="retractMessage" @click="retractEventMessage(1)">
            <i class="iconfont icon-shouqi"></i>
          </div>
        </div>
        <div class="messagePushItemBox">
          <div
            class="messagePushItem"
            v-for="(item, index) in doorMessagePushData"
            :key="index"
          >
            <iframe
              class="iframeBox"
              src="about:blank"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              style="
                top: 0;
                left: 0;
                position: absolute;
                visibility: inherit;
                width: 100%;
                height: 100%;
                z-index: -1;
                filter: alpha(opacity=0);
              "
            ></iframe>
            <div class="messagePushTitle">
              {{ item.doorName }}{{ item.responseStatus }}
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 声音 -->
    <audio
      src="@/assets/statistic/test.mp3"
      autoplay
      controls
      ref="audio"
      v-show="false"
    ></audio>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import Ajax from "@/common/dataApi/navigation.js";
import api from "@/common/dataApi/globalSecurity/faceControlsApi";
import { Sockets } from "@/js/socket-util.js";
import { sysRegistry } from "@/common/registry/sysRegistry";
// import commonUtils from "@/common/utils/common";
// const IMGBASEURL = BASEURL + commonUtils.getRegistry("FILEMGMT");
export default {
  name: "ViewWebsocket",
  data() {
    return {
      autoplay: false,
      // websocket 容器
      websocket: [],
      userInfo: {},
      // 事件推送的容器
      eventMessagePushData: [],
      // 能耗推送容器
      eneryMessagePushData: [],
      // 门禁推送容器
      doorMessagePushData: [],
      // webSocket保活
      websocketHeartTimeout: [
        {
          timeout: 3000,
          timeoutObj: null,
          serverTimeoutObj: null
        },
        {
          timeout: 3000,
          timeoutObj: null,
          serverTimeoutObj: null
        },
        {
          timeout: 3000,
          timeoutObj: null,
          serverTimeoutObj: null
        },
        {
          timeout: 3000,
          timeoutObj: null,
          serverTimeoutObj: null
        },
        {
          timeout: 3000,
          timeoutObj: null,
          serverTimeoutObj: null
        }
      ],
      isShowEvt: true, // 是否展示事件推送
      isShowDoor: true,
      isEneryShow: true, // 是否展示能耗推送
      lockReconnect: [false, false, false, false, false] // 是否可以重连
    };
  },
  computed: {
    ...mapGetters("baseUser", ["getItem"]),
    ...mapState("baseUser", ["_info"]),
    ...mapState("security", ["removeIncidentNumber"]),
    ...mapGetters("basePosition", ["positionInfo"]),
    ...mapState("navigation", ["allPosLeafnodeCodeArr"]),
    // 当前园区编号
    currentPark() {
      return this.positionInfo.posCode || "";
    },
    UID() {
      return this.getItem("uid") || "";
    },
    // 判断告警页面路由
    routes() {
      return this.$route.path;
    }
  },
  watch: {
    _info(next) {
      next && sessionStorage.getItem("IOC_WEB_SESSION") && this.pageInit();
    },
    // 点击移除当前推送
    removeIncidentNumber(removeNumber) {
      this.eventMessagePushData = this.eventMessagePushData.filter((item) => {
        return item.incidentNumber !== removeNumber;
      });
    }
  },
  created() {
    this.UID && sessionStorage.getItem("IOC_WEB_SESSION") && this.pageInit();
    // alert(window.isOpen);
  },
  mounted() {
    this.eventMessagePushData = [];
    try {
      this.$refs.audio.muted = "muted";
      window.addEventListener("click", this.playAudio);
    } catch (err) {}
  },
  beforeDestroy() {
    this.removeSocket();
  },
  methods: {
    ...mapMutations("navigation", [
      "webSocketChange",
      "eventTotal",
      "leftNavPathChange"
    ]),
    playAudio() {
      try {
        this.$refs.audio.muted = "muted";
        this.$refs.audio.play();
      } catch (err) {}
    },
    // ...mapMutations(["fillUser"]),
    routerPush(path) {
      let index = path.indexOf("?");
      let rPath =
        index !== -1
          ? `${path}&t=${new Date().getTime()}`
          : `${path}?t=${new Date().getTime()}`;
      window.history.pushState(null, null, rPath);
    },
    // 初始化
    async pageInit() {
      this.removeSocket();
      Object.assign(this.$data, this.$options.data());
      try {
        const uid = this.UID;
        let parkStr = this.positionInfo.posCode;
        if (uid) {
          // 必须存在用户uid才链接
          if (this.websocket.length > 0) {
            this.websocket.forEach((socket, idx) => {
              +socket.readyState() !== 1 &&
                this.conectWebSocket(socket.url, idx);
            });
            return false;
          }
          let topic = [
            "/INCIDENTINFO", // 安防事件中心
            "/equipmentIncident", // 设施事件
            "/REPAIR" // 报修事件
          ];
          /**
           * @desc 建立websocket连接
           */
          const commonUrl = `/${uid}/`;
          const baseUrl = `${sysRegistry.WEBSOCKET_URL}/ioc/websocket/websocket`; // uat

          topic.forEach((url, idx) => {
            this.conectWebSocket(
              `${baseUrl}${url}${commonUrl}${parkStr}`, // uat
              idx
            );
          });
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    perpetualLogin() {
      setTimeout(() => {
        Ajax.getButtonsPermList();
        this.perpetualLogin();
      }, 600000);
    },
    async conectWebSocket(url, type) {
      /**
       * @desc 建立webSocket接收实时数据
       * @param url websocket地址
       * @param type
       *  0 安防事件中心
       *  1 设施事件中心
       *  2 安防事件总数
       *  3 门禁
       *  4 视频巡逻提醒
       */
      this.websocketHeartTimeout[type] = {
        timeout: 3000,
        timeoutObj: null,
        serverTimeoutObj: null
      };
      let _this = this;
      try {
        // 判断当前的浏览器是否支持websocket.
        if ("WebSocket" in window) {
          this.websocket[0] = new Sockets({
            url,
            onmessage: (event) => {
              if (
                JSON.parse(event.data).soundStatus === 1 &&
                sessionStorage.getItem("soundValue") === "true"
              ) {
                try {
                  this.$refs.audio.muted = "";
                  _this.$refs.audio.play();
                  setTimeout(() => {
                    this.$refs.audio.muted = "muted";
                  }, 5000);
                } catch (err) {}
              }
              _this.msgHandle(event, type);
            },
            onopen: (event) => {},
            onerror: () => {
              _this.webSocketReconnect(url);
            },
            onclose: () => {
              _this.webSocketReconnect(url);
            }
          });
        } else {
          alert(`${this.$t("security_290_gis")}websocket`);
          return false;
        }
      } catch (err) {
        console.log("err", err);
        // webSocket重连
        this.webSocketReconnect(url);
      }
    },
    // webSocket重连
    webSocketReconnect(url, type = 0) {
      if (this.lockReconnect[type]) return;
      this.lockReconnect[type] = true;
      this.websocketHeartTimeout[type].timeID = setTimeout(() => {
        this.conectWebSocket(url);
        this.lockReconnect[type] = false;
      }, 3000);
    },
    // 处理服务端推送的消息
    msgHandle(msg, type) {
      if (msg.data === "true") {
        this.websocket[type].send("ping");
        return;
      }
      let data = typeof msg.data === "string" ? JSON.parse(msg.data) : msg.data;
      switch (type) {
        case 0: // 安防事件中心
          this.incidentHandle(data);
          break;
        case 1: // 设施事件中心
          this.equipmentHandle(data);
          break;
        case 2: // 报修事件
          this.repairHandle(data);
          break;
        case 3: // 安防事件总数
          this.accountHandle(data);
          break;
        case 4: // 门禁消息
          this.doorHandle(data);
          break;
        case 5: // 视频巡逻提醒
          this.patrolHandle(data);
          break;
        case 6: // 能耗推送
          this.energyConsumption(data);
          break;
        default:
          break;
      }
    },
    // 展开消息
    openEventMessage(type) {
      if (type === 0) {
        this.isShowEvt = true;
      } else if (type === 2) {
        this.isEneryShow = true;
      } else {
        this.isShowDoor = true;
      }
    },
    // 收起推送消息
    retractEventMessage(type) {
      if (type === 0) {
        this.isShowEvt = false;
      } else if (type === 2) {
        this.isEneryShow = false;
      } else {
        this.isShowDoor = false;
      }
    },
    /**
     * @desc 处理安防事件中心的推送消息
     * @param type=0
     * @param incidentNumber 事件编号
     * @param incidentTitle 事件名称
     */
    incidentHandle(msgObj) {
      this.openEventMessage(0);
      let router = "";
      let type;
      if (
        this.routes !== "/global/event-center" &&
        this.routes !== "/pcv/securityEventCenter" &&
        msgObj.topic !== "equipmentIncident"
      ) {
        router = "/securitygis/global/event-center";
        type = 0;
      } else if (
        this.routes === "/global/event-center" &&
        msgObj.topic !== "equipmentIncident"
      ) {
        router = "/securitygis/global/event-center";
        type = 0;
      } else if (
        this.routes === "/pcv/securityEventCenter" &&
        msgObj.topic !== "equipmentIncident"
      ) {
        router = "/securitygis/pcv/securityEventCenter";
        type = 0;
      } else if (msgObj.topic === "equipmentIncident") {
        router = "/facilityEventCenter";
        type = 5;
      } else {
      }
      let {
        data: {
          parkCode = "",
          subtypeCode = "",
          suspectId = "",
          objectId = "",
          incidentNumber = "",
          incidentTitle = "",
          incidentDetail = "",
          levelCode = "",
          levelNameCn = "",
          openDate = ""
        }
      } = msgObj;
      openDate = this.format(openDate, "yyyy.MM.dd HH:mm:ss");
      const simpleMsg = {
        type,
        parkCode,
        subtypeCode,
        suspectId,
        objectId,
        incidentNumber,
        incidentTitle,
        incidentDetail,
        levelCode,
        levelNameCn,
        openDate,
        router,
        animationClass: "websocketFade"
      };
      this.combinePushData(simpleMsg, 0);
    },
    /**
     * @desc 处理设施事件中心消息
     * @param type=1
     * @param id 设备编号
     * @param incidentTitle 设备名称
     * @param levelCode 园区编码
     */
    equipmentHandle(msgObj) {
      this.openEventMessage(0);
      let {
        data: { incidentNumber = "", incidentTitle = "", openDate = "" }
      } = msgObj;
      openDate = this.format(openDate, "yyyy.MM.dd HH:mm:ss");
      let router = "";
      let type;
      if (msgObj.topic === "equipmentIncident") {
        router = "/facilityEventCenter";
        type = 5;
      }
      const simpleMsg = {
        type,
        incidentTitle,
        openDate,
        incidentNumber,
        router,
        animationClass: "websocketFade"
      };
      this.combinePushData(simpleMsg, 1);
    },
    /**
     * @desc 报修事件中心消息
     * @param type=2
     * @param id 设备编号
     * @param incidentTitle 设备名称
     * @param levelCode 园区编码
     */
    repairHandle(msgObj) {
      this.openEventMessage(0);
      let {
        data: {
          incidentNumber = "",
          incidentTitle = "",
          createDate = ""
        }
      } = msgObj;
      createDate = this.format(createDate, "yyyy.MM.dd HH:mm:ss");
      let router = "";
      let type;
      if (msgObj.topic === "REPAIR") {
        router = "/estatesWarrantyCenter";
        type = 6;
      } else {
        router = "/securitygis/global/event-center";
        type = 6;
      }
      const simpleMsg = {
        type,
        incidentTitle,
        openDate: createDate,
        incidentNumber,
        router,
        animationClass: "websocketFade"
      };
      this.combinePushData(simpleMsg, 2);
    },
    /**
     * @desc 处理安防事件总数
     * @param type=3
     */
    accountHandle(msgObj) {
      let eventTotal = msgObj.data;
      this.eventTotal({ eventTotal });
    },
    /**
     * @desc 处理门禁事件推送
     * @param type=4
     * @param id 门禁编号
     * @param flag 门禁状态
     * @param equipCn 设备名称
     * @param responseStatus 开门结果
     */
    doorHandle(msgObj) {
      this.openEventMessage(1);
      const {
        data: { id = "", flag = "", equipCn = "", responseStatus = "" }
      } = msgObj;
      const simpleMsg = { id, flag, equipCn, responseStatus };
      this.doorMessagePushData.unshift(simpleMsg);
    },
    /**
     * @desc 处理能耗诊断信息
     * @param type=5
     * @param deviceId 设备id
     * @param deviceName 设备名称
     * @param faultName 故障名称
     * @param time 时间
     * @param faultId 故障ID
     */
    energyConsumption(msgObj) {
      this.openEventMessage(2);
      const simpleMsg = {
        faultName: msgObj.bean.faultName,
        faultId: msgObj.bean.faultId,
        time: msgObj.bean.time,
        router: "/faultHistory",
        deviceName: msgObj.bean.deviceName,
        deviceId: msgObj.bean.deviceId,
        animationClass: "websocketFade"
      };
      this.eneryMessagePushData.unshift(simpleMsg);
    },
    /**
     * @desc 处理视频巡逻提醒消息
     * @param type=4
     * @param id 巡逻计划id
     * @param patrolRemindName 巡逻计划名称
     * @param planPlayTime 巡逻计划执行时间
     */
    patrolHandle(msgObj) {
      this.openEventMessage(0);
      let { parkCode, planPlayTime, patrolRemindName } = msgObj;
      const simpleMsg = {
        type: 4,
        parkCode,
        openDate: planPlayTime,
        incidentTitle: `${patrolRemindName} ${this.$t("security_730_gis")}`,
        router: "/securitygis/pcv/videoPatrol",
        animationClass: "websocketFade"
      };
      this.combinePushData(simpleMsg);
    },
    // 时间日期转化
    format(time, format) {
      var t = new Date(time);
      var tf = function(i) {
        return (i < 10 ? "0" : "") + i;
      };
      return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
        switch (a) {
          case "yyyy":
            return tf(t.getFullYear());
          case "MM":
            return tf(t.getMonth() + 1);
          case "mm":
            return tf(t.getMinutes());
          case "dd":
            return tf(t.getDate());
          case "HH":
            return tf(t.getHours());
          case "ss":
            return tf(t.getSeconds());
        }
      });
    },
    // 关闭推送消息
    closeWebSocket(index, url) {
      this.eventMessagePushData[index].animationClass = "websocketFadeOut";
      let timeout = setTimeout(() => {
        this.eventMessagePushData.splice(index, 1);
        clearTimeout(timeout);
      }, 900);
      this.leftNavPathChange(url);
    },
    // 关闭推送消息
    closeEneryWebSocket(index, url) {
      this.eneryMessagePushData[index].animationClass = "websocketFadeOut";
      let timeout = setTimeout(() => {
        this.eneryMessagePushData.splice(index, 1);
        clearTimeout(timeout);
      }, 900);
      this.leftNavPathChange(url);
    },
    // 组装推送数据
    combinePushData(simpleMsg, type = "") {
      this.eventMessagePushData.unshift(simpleMsg);
      let pushLen = this.eventMessagePushData.length;
      if (pushLen > 6) {
        // 页面上显示的推送最多为6条
        for (let i = 1; i < pushLen - 5; i++) {
          this.closeWebSocket(pushLen - i);
        }
      }
      // const NEED_UPDATE_PATH = [
      //   "/pcv/securityEventCenter",
      //   "/facilityEventCenter",
      //   "/estatesWarrantyCenter"
      // ];
      // const path = this.$route.path || "";
      // const webSocketChange = "webSocket" + new Date().getTime();
      // type === 0 && NEED_UPDATE_PATH.includes(path) && this.webSocketChange({ webSocketChange });
      // type === 1 && path === NEED_UPDATE_PATH[1] && this.webSocketChange({ webSocketChange });
      // type === 2 && path === NEED_UPDATE_PATH[2] && this.webSocketChange({ webSocketChange });

      const NEED_UPDATE_PATH = [
        "/pcv/securityEventCenter",
        "/global/event-center",
        "/facilityEventCenter",
        "/estatesWarrantyCenter"
      ];
      const path = this.$route.path || "";
      const webSocketChange = "webSocket" + new Date().getTime();
      type !== "" &&
        NEED_UPDATE_PATH.includes(path) &&
        this.webSocketChange({ webSocketChange });
    },
    closeAllEventMessage(type) {
      if (type === 0) {
        this.eventMessagePushData = [];
        this.eventMessageShowStatus = true;
      } else if (type === 2) {
        this.eneryMessagePushData = [];
      } else {
        this.doorMessagePushData = [];
        this.doorMessageShowStatus = true;
      }
    },
    removeSocket() {
      this.websocket.length > 0 &&
        this.websocket.forEach((socket) => {
          "close" in socket &&
            typeof socket.close === "function" &&
            socket.close();
        });
    },
    // 跳转到人脸追踪页面
    faceTracking(suspectId, parkCode, objectId) {
      this.faceTrackingLoading = true;
      this.getFaceTrackingInfo(suspectId, parkCode, objectId).then((res) => {
        if (res) {
          let faceTrackingControlInfo = res;
          faceTrackingControlInfo.objectId = objectId;
          let faceTrackingControlInfoObj = JSON.stringify(
            faceTrackingControlInfo
          );
          sessionStorage.setItem(
            "faceTrackingControlInfo",
            faceTrackingControlInfoObj
          );
          this.$router.push({
            path: "/pcv/securityEventCenter/personTrajectory",
            query: { t: Date.now() }
          });
        }
      });
    },
    // 点击人脸追踪获取数据方法
    getFaceTrackingInfo(suspectId, parkCode, objectId) {
      let params = {
        suspectId,
        parkCode,
        personId: objectId
      };
      return api
        .getFaceTrackingInfo(params)
        .then((res) => {
          if (res.code !== 100000) {
            this.$message.error(res.message);
          }
          if (res.data.hasOwnProperty("error")) {
            // 如果该园区已经有追踪任务
            this.faceTrackingLoading = false;
            this.iserror = true;
            this.messAgeText = res.message;
            this.errorShow = "2";
            return Promise.resolve(false);
          } else {
            let data = res.data;
            let params = {
              faceControlInfo: {
                faceTrackingId: data.trackTaskRecordsVo.trackTaskId,
                suspectId: data.trackTaskRecordsVo.suspectId,
                parkCode: this.parkCode,
                taskTitle: data.trackTaskRecordsVo.taskTitle,
                typeCode: data.trackTaskRecordsVo.typeCode,
                typeNameCn: data.trackTaskRecordsVo.typeNameCn,
                personName: data.controlPictureInfo.value6,
                nameCnEnc: data.controlPictureInfo.nameCnEnc
              }
            };
            return Promise.resolve(params);
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.faceTrackingLoading = false;
          this.iserror = true;
          this.messAgeText = this.$t("security_731_gis");
          this.errorShow = "2";
        });
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    formatTime(shijianchuo) {
      // shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    }
    /**
     * @desc 当前webSocket暂时没有用的方法
    websocketHeartStart(type) {
      let timeout = this.websocketHeartTimeout[type].timeout;
      this.websocketHeartTimeout[type].timeoutObj = setTimeout(() => {
        if (type === 4) {
          this.websocket[type].send("PersonTrack");
        } else {
          this.websocket[type].send("ping");
        }
        this.websocketHeartTimeout[type].serverTimeoutObj = setTimeout(() => {
          this.websocket[type].close();
        }, timeout);
      }, timeout);
    },
    websocketHeartReset(type) {
      clearTimeout(this.websocketHeartTimeout[type].serverTimeoutObj);
      clearTimeout(this.websocketHeartTimeout[type].timeoutObj);
    }
    */
  }
};
</script>
<style lang="less" scoped>
.wiew-websocket {
  // width: 100%;
  // height: 100%;
  background-color: #fff;
  .icon-guanbi {
    width: 12px;
    height: 12px;
    line-height: 12px;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #ffffff;
    &:before {
      width: 12px;
      height: 12px;
      font-size: 12px;
    }
  }
  .openControl {
    position: relative;
    width: 100%;
    height: 2.875rem;
    line-height: 2.875rem;
    // background-color: rgba(39, 47, 73, 0.9);
    overflow: hidden;
    font-size: 0.875rem;
    margin-top: 5px;
  }
  .openMessage {
    text-align: center;
    width: 2.875rem;
    height: 2.875rem;
    float: right;
    color: #fff;
    cursor: pointer;
  }
  .eventMessageOpen {
    width: 3rem;
    height: 2.875rem;
    line-height: 2.875rem;
    position: fixed;
    right: 1rem;
    top: 115px;
    z-index: 1999;
    color: #fff;
  }
  .pushControl {
    position: relative;
    width: 100%;
    height: 2.875rem;
    line-height: 2.875rem;
    // background-color: rgba(39, 47, 73, 0.9);
    overflow: hidden;
    font-size: 0.875rem;
    margin-top: 5px;
    .retractMessage {
      width: 2.875rem;
      height: 2.875rem;
      float: right;
      color: #fff;
      text-align: center;
      i {
        width: 12px;
        height: 12px;
        line-height: 12px;
        cursor: pointer;
        color: #ffffff;
        font-size: 0.75rem;
      }
    }
  }
  .messagePushItem {
    width: 100%;
    height: 2.875rem;
    line-height: 2.875rem;
    background-color: rgba(39, 47, 73, 0.9);
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    margin-top: 5px;
    .messagePushTitle {
      width: auto;
      height: 100%;
      max-width: calc(~"30rem - 120px");
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #8091ac;
      float: left;
      margin-left: 1.25rem;
    }
    .messagePushSee {
      width: auto;
      height: 100%;
      float: left;
      cursor: pointer;
      margin-left: 0.3125rem;
      a {
        color: #3a7aea;
      }
    }
  }
  .websocketFade {
    animation: fadeInDown linear 1s;
  }
  .websocketFadeOut {
    animation: fadeOutDown linear 1s;
  }
  .eventMessagePush {
    width: 30rem;
    height: auto;
    max-height: calc(~"100% - 190px");
    position: fixed;
    right: 1rem;
    top: 115px;
    z-index: 1999;
  }
  .doorMessageOpen {
    width: 3rem;
    height: 2.875rem;
    line-height: 2.875rem;
    position: fixed;
    right: 1rem;
    bottom: 0;
    z-index: 1999;
    color: #fff;
  }
  .doorMessagePush {
    width: 30rem;
    height: 18rem;
    overflow: hidden;
    position: fixed;
    right: 1rem;
    bottom: 30px;
    z-index: 1999;
    .messagePushItemBox {
      width: 100%;
      height: auto;
      max-height: calc(~"100% - 2.875rem");
      overflow-y: auto;
    }
  }
}
</style>
