import Ajax from "@/common/utils/common";
const BASEURL = Ajax.getRegistry("SAFETYMGMT");
export default {
  namespaced: true,
  state: {
    cameraInfo: {},
    videoPlayTitle: "",
    videoPlayControl: false,
    changeMap: true,
    fromOtherPage: "",
    patrolGroupCamera: [],
    currentPlayIndex: 0, // 当前摄像机列表下表
    emptyLocationNum: 0, // 当前摄像机列表下经纬度为空的数量
    ocxPlayControlStatus: { status: true, type: 1, isReset: false }, // 1:表示点击暂停播放按钮,2:表示点击播放器右上角关闭按钮
    patrolGroupInfo: { defaultPlayTime: 0, taskHeaderId: "" },
    dataQueryStatus: false,
    patrolListLength: 0,
    rightPlayStatus: false, // 右侧播放状态
    patrolRemindExecuteId: 0, // 任务ID
    needRedraw: true // 需要重画整条巡逻线路时的标志
    // checkCamera:{}, // 实时监控保留已勾选的摄像机{locationCode:{},cameraList:[]};
  },
  mutations: {
    patrolGroupMut(state, data) {
      if (!data.cameraInfo) { return; };
      state.cameraInfo = data.cameraInfo;
      if (data.currentPlayIndex === 0) {
        state.emptyLocationNum = 0;
      }
      if (!data.cameraInfo.latitude ||
        !data.cameraInfo.longitude ||
        data.cameraInfo.latitude === "null" ||
        data.cameraInfo.longitude === "null"
      ) {
        state.emptyLocationNum++;
      }
      state.videoPlayTitle = `${
        data.patrolGroupName ? data.patrolGroupName : ""
      }-${state.cameraInfo.camera ? state.cameraInfo.camera : ""}`;
      state.patrolGroupCamera = data.patrolGroupCamera;
      state.currentPlayIndex = data.currentPlayIndex;
      state.changeMap = !state.changeMap;
      // 当地图经纬度不为空时，才改变地图
      // if (data.cameraInfo.latitude &&
      //   data.cameraInfo.longitude &&
      //   data.cameraInfo.latitude !== "null" &&
      //   data.cameraInfo.longitude !== "null"
      // ) {
      //   state.changeMap = !state.changeMap;
      // }
    },
    videoPlayControlMut(state, data) {
      state.videoPlayControl = data;
    },
    changeFromPage(state, data) {
      state.fromOtherPage = data;
    },
    ocxPlayControlStatusMut(state, data) {
      state.ocxPlayControlStatus = data;
    },
    defaultPlayTimeMut(state, data) {
      state.patrolGroupInfo = data;
    },
    // checkCameraMut(state, data) {
    //   state.checkCamera = data;
    // },
    loolData(state) {

    },
    changeNeedRedraw(state, data) {
      state.needRedraw = data;
    },
    resetData(state) {
      state.cameraInfo = {};
      state.videoPlayTitle = "";
      state.videoPlayControl = true;
      state.changeMap = !state.changeMap;
      state.patrolGroupCamera = [];
      state.ocxPlayControlStatus = { status: true, type: 1, isReset: true };
      state.patrolGroupInfo = { defaultPlayTime: 0, taskHeaderId: "" };
      state.rightPlayStatus = false;
    },
    resetTitle(state, data) {
      state.videoPlayTitle = data;
    },
    getPatrolListLength(state, data) {
      state.patrolListLength = data;
    },
    rightPlayStatusFn(state, data) {
      state.rightPlayStatus = data;
    },
    patrolRemindExecuteIdFn(state, data) {
      state.patrolRemindExecuteId = data;
    }
  },
  actions: {
    recordPatrolTime({commit}, params) {
      return new Promise((resolve, reject) => {
        Ajax.axiosData(
          "/patrol/updateVideoPatrol",
          "POST",
          params,
          BASEURL
        ).then((res) => {
        });
      });
    }
  }
};
