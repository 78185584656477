
import Vue from "vue";

import commonComponent from "./components/common/js/commonComponent"; // 公用全局组件引入
import clickoutside from "element-ui/src/utils/clickoutside";
import "./directive/directives.js";
import { RES_CODE_SUCCESS } from "@/common/utils/constant";
// 图片裁剪
import mcropper from "@/pages/securityEventCenter/newComponents/mcropper";
import InfiniteLoading from "vue-infinite-loading";
import moment from "moment";
Vue.use(mcropper);
Vue.use(InfiniteLoading);
Vue.prototype.$moment = moment;
let browserType = UtilFn.browserType();

/* 听云兼容 */
if (!window.TINGYUN) {
  var TINGYUN = window.TINGYUN = {};
  TINGYUN.createEvent = function() {
    var e = {};
    e.end = function() {};
    e.fail = function() {};
    return e;
  };
}

Vue.use(commonComponent);

Vue.config.productionTip = false;
Vue.prototype.$RES_CODE_SUCCESS = RES_CODE_SUCCESS;

Vue.prototype.$MAP_DEFAULT_ZOOM = {
  COUNTRY: 3,
  AREA: 5,
  CITY: 9,
  PARK: 18
};
Vue.prototype.$vuePubSub = new Vue();
Vue.directive("clickoutside", clickoutside);
Vue.directive("drag-stack", {
  bind: el => {
    el.classList.add("drag-stack");
    el.style["z-index"] = 1000;
    el.onclick = ev => {
      window.event ? (window.event.cancelBubble = true) : ev.stopPropagation();
      let tempArr = document.querySelectorAll(".drag-stack");
      for (let i = 0, len = tempArr.length; i < len; i++) {
        tempArr[i].style["z-index"] = 1000;
      }

      el.style["z-index"] = parseInt(el.style["z-index"]) + 1;
    };
  }
});

// 截流，控制在wait时间内func只会被触发一次
let throttleInitTime = 0;
const throttle = (func, wait) => {
  return function() {
    let pointer = this;
    var args = arguments;
    let nowTime = new Date();
    if (nowTime - throttleInitTime > wait) {
      func.apply(pointer, args);
      throttleInitTime = nowTime;
    }
  };
};
Vue.prototype.throttle = throttle;
function zero(s) {
  // 自动补零
  return s < 10 ? `0${s}` : s;
}

const Dateformat = (date, formatter) => {
  // 时间格式
  let f = formatter || "yyyy/MM/dd HH:mm:ss";
  const t = {};
  t.yyyy = date.getFullYear();
  t.MM = zero(date.getMonth() + 1);
  t.dd = zero(date.getDate());
  t.HH = zero(date.getHours());
  t.mm = zero(date.getMinutes());
  t.ss = zero(date.getSeconds());
  ["yyyy", "MM", "dd", "HH", "mm", "ss"].forEach(item => {
    f = f.replace(item, t[item]);
  });
  return f;
};
Vue.prototype.Dateformat = Dateformat;

export default {
  browserType
};
