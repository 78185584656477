import createPersistedState from "vuex-persistedstate";

let pluginArr = [];
let createPersistedState1 = createPersistedState({
  storage: window.sessionStorage,
  key: "GLOBAL_IOC",
  paths: [
    "userInfo", // 旧 用户信息
    "leftNavList", // 左侧菜单
    "btnAuthObj", // 按钮权限
    "baseUser._info", // 用户信息
    "navigation.allPosLeafnodeCodeArr", // 全部园区code数组
    "isBIMMap", // 是否是BIM地图
    "itgHouse.houseInfo" // 智慧人居当前房屋信息
  ]
});

pluginArr.push(createPersistedState1);

export default pluginArr;
