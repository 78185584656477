export default {
  namespaced: true,
  state: {
    // “IS_END： 代表巡逻结束；IS_PAUSE_WAIT： 代表视频从暂停恢复到播放的这段时间，因为计时器要接着播放，所以单独标记”
    patrolPreviewState: "UN_START" // "IS_RUN" "IS_PAUSE" "IS_END" "IS_PAUSE_WAIT"
  },
  mutations: {
    changeLinePatrolState(state, status) {
      state.patrolPreviewState = status;
    }
  },
  getters: {
    patrolPreviewState(state) {
      return state.patrolPreviewState;
    }
  }
};