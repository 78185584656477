<template>
  <div class="nothingMore" >
    <div class="nothingMoreContent">
      <i class="iconfont" :class="iconfontClassStr"></i>
      <br>
      <span v-if="iconfontClassStr === 'icon-juxing'">{{noDataText}}</span>
      <span v-if="iconfontClassStr === 'icon-gantanhao'">{{ $t('security_20_gis') }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "noDataShowContent",
  props: {
    iconfontClassStr: {
      type: String,
      default: "icon-juxing"
    },
    noDataText: {
      type: String,
      default() {
        return this.$t("security_21_gis");
      }
    }
  },
  components: {}
};
</script>
<style lang="less" scoped>
.nothingMoreContent{
  &>span{
    color:#323b59;
  }
}
</style>
