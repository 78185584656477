
const securityEventFiles = () =>
  import(/* webpackChunkName: "securityEventFiles" */ "@/pages/securityEventFiles/securityEventFiles");

const fireEventFiles = () =>
  import(/* webpackChunkName: "fireEventFiles" */ "@/pages/securityEventFiles/securityEventFiles"); // 消防事件列表
const securityIncidentsStatistics = () =>
  import(/* webpackChunkName: "eventFiles" */ "@/pages/globalSecurity/securityIncidentsStatistics"); // 安防事件统计
const staffTracking = () =>
  import(/* webpackChunkName: "staffTracking" */ "@/pages/staffTracking/staffTracking"); // 人员追踪
const staffTrackingDetails = () =>
  import(/* webpackChunkName: "staffTrackingDetails" */ "@/pages/staffTracking/components/staffTrackingDetails"); // 人员追踪详情
const addStaffTracking = () =>
  import(/* webpackChunkName: "addStaffTracking" */ "@/pages/staffTracking/components/addStaffTracking"); // 人员追踪新建
const operationLog = () =>
  import(/* webpackChunkName: "operationLog" */ "@/pages/operationLog/operationLog.vue");
const addMaskCheck = () =>
  import(/* webpackChunkName: "addMaskCheck" */ "@/pages/staffTracking/components/addMaskCheck"); // 新建口罩检测
const maskWarn = () =>
  import(/* webpackChunkName: "maskWran" */ "@/pages/staffTracking/components/maskWarn/maskWarn"); // 口罩告警
const showStaffTracking = () =>
  import(/* webpackChunkName: "showStaffTracking" */ "@/pages/staffTracking/components/showStaffTracking"); // 人员追踪新建
const faceControls = () =>
  import(/* webpackChunkName: "faceControl" */ "@/pages/staffTracking/components/faceControls"); // 人脸布控
const faceControlDetails = () =>
  import(/* webpackChunkName: "faceControlDetails" */ "@/pages/staffTracking/components/faceControlDetails"); // 人脸布控详情
const faceTrackingManagement = () =>
  import(/* webpackChunkName: "faceTrackingManagement" */ "@/pages/staffTracking/components/faceTrackingManagement"); // 人脸追踪事件管理
const securityTaskManagement = () =>
  import(/* webpackChunkName: "securityTaskManagement" */ "@/pages/securityTaskManagement/index");
const securityTaskManagementDatails = () =>
  import(/* webpackChunkName: "securityTaskManagementDatails" */ "@/pages/globalSecurity/securityTaskManagementDatails");
const addFaceControl = () =>
  import(/* webpackChunkName: "addFaceControl" */ "@/pages/globalSecurity/addFaceControl.vue");
const addFaceTrack = () =>
  import(/* webpackChunkName: "addFaceTrack" */ "@/pages/globalSecurity/addFaceTrack.vue");
const noCascadeMenu = () =>
  import(/* webpackChunkName: "noCascadeMenu" */ "@/pages/noCascadeMenu");

const faceTrackingIndex = () =>
  import(/* webpackChunkName: "faceTrackingIndex" */ "@/pages/pcv/faceTracking"); // 人脸追踪
const faceLibraryPage = () =>
  import(/* webpackChunkName: "faceControl" */ "@/pages/globalSecurity/faceLibraryPage"); // 人脸库
const faceLibraryDetail = () =>
  import(/* webpackChunkName: "faceControl" */ "@/pages/globalSecurity/faceLibraryDetail"); // 人脸库详情
const customAnalyze = () =>
  import(/* webpackChunkName: "pointLocationInformation" */ "@/pages/globalSecurity/customAnalyze"); // 人员客群分析

const alohaSystemPage = () =>
  import(/* webpackChunkName: "alohaSystemPage" */ "@/pages/AlohaSystem/index"); // 迎宾系统
const fireManagementStatics = () =>
  import(/* webpackChunkName: "fireManagementStatics" */ "@/pages/globalSecurity/fireManagement/fireManagementStatics");
const videoPage = () =>
  import(/* webpackChunkName: "fireManagementStatics" */ "@/pages/videoPage/index");

const patrolRoute = () =>
  import(/* webpackChunkName: "patrolRoute" */ "@/pages/patrolRoute/index.vue"); // 巡更路线
const patrolList = () =>
  import(/* webpackChunkName: "patrolList" */ "@/pages/patrolList/index.vue"); // 巡

export default [
  {
    path: "/securityEventFiles", // 事件档案
    name: "securityEventFiles",
    component: securityEventFiles
  },
  {
    path: "/fireEventFiles", // 消防事件列表
    name: "fireEventFiles",
    component: fireEventFiles
  },
  {
    path: "/securityIncidentsStatistics", // 安防事件统计
    name: "securityIncidentsStatistics",
    component: securityIncidentsStatistics
  },
  {
    // 安防事件详情
    path: "securityEventFiles/securityEventDetailsLive",
    name: "securityEventDetailsLive",
    component: () => import(/* webpackChunkName: "securityEventDetailsLive" */ "@/pages/securityEventFiles/securityEventDetails") // 公有云事件档案安防事件详情
  },
  {
    path: "/staffTracking", // 人员追踪
    name: "staffTracking",
    component: staffTracking
  },
  {
    path: "/staffTracking/:id", // 人员追踪
    name: "staffTrackingDetail01",
    component: staffTracking
  },
  {
    path: "operationLog", // 操作日志
    name: "operationLog",
    component: operationLog
  },
  {
    path: "/staffTracking/staffTrackingDetails/:id", // 人员追踪详情
    name: "staffTrackingDetails",
    component: staffTrackingDetails
  },
  {
    path: "/staffTracking/addStaffTracking/:id", // 人员追踪新建
    name: "addStaffTracking01",
    component: addStaffTracking
  },
  {
    path: "/staffTracking/addStaffTracking", // 人员追踪新建
    name: "addStaffTracking",
    component: addStaffTracking
  },
  {
    path: "/staffTracking/addMaskCheck/MASK_CHECK",
    name: "addMaskCheck",
    component: addMaskCheck
  },
  {
    path: "/staffTracking/modifyMaskCheck/MASK_CHECK",
    name: "modifyMaskCheck",
    component: addMaskCheck
  },
  {
    path: "/staffTracking/MASK_CHECK/maskWarn", // 口罩告警列表
    name: "maskWarn",
    component: maskWarn
  },
  {
    path: "/staffTracking/showStaffTracking/:id", // 展示迎宾屏
    name: "showStaffTracking",
    component: showStaffTracking
  },
  {
    path: "/staffTracking/modifyStaffTracking/:id", // 人员追踪修改
    name: "modifyStaffTracking",
    component: addStaffTracking
  },
  {
    path: "/faceControls", // 人脸布控
    name: "faceControls",
    component: faceControls
  },
  {
    path: "/faceControls/faceControlDetails/:id", // 人脸布控详情
    name: "faceControlDetails",
    component: faceControlDetails
  },
  {
    path: "/staffTracking/faceTrackingManagement/FACE_TRACKING", // 人脸追踪事件管理
    name: "faceTrackingManagement",
    component: faceTrackingManagement
  },
  {
    path: "/securityTaskManagement", // 安防任务管理
    name: "securityTaskManagement",
    component: securityTaskManagement
  },
  {
    path: "/securityTaskManagement/securityTaskManagementDatails/:id", // 安防任务详情
    name: "securityTaskManagementDatails",
    component: securityTaskManagementDatails
  },
  {
    path: "/faceControls/addFaceControl", // 新建人脸布控
    name: "addFaceControl",
    component: addFaceControl
  },
  {
    path: "/faceControls/addFaceTrack", // 新建人脸追踪
    name: "addFaceTrack",
    component: addFaceTrack
  },
  {
    path: "/faceControls/modifyFaceControl", // 修改人脸布控
    name: "modifyFaceControl",
    component: addFaceControl
  },
  {
    path: "/faceControls/againFaceControl", // 重新人脸布控
    name: "againFaceControl",
    component: addFaceControl
  },
  {
    path: "/noCascadeMenu",
    name: "noCascadeMenu",
    component: noCascadeMenu
  },
  {
    path: "/staffTracking/pcv/publicCloudFaceTracking", // 人脸追踪
    name: "faceTrackingIndex",
    component: faceTrackingIndex
  },
  {
    path: "/staffTracking/pcv/publicCloudFaceTracking", // 人员追踪下的人脸追踪按钮跳转页面
    name: "faceTrackingIndex2",
    component: faceTrackingIndex
  },
  {
    path: "/faceLibraryPage", // 人脸库
    name: "faceLibraryPage",
    component: faceLibraryPage
  },
  {
    path: "/faceLibraryPage/faceLibraryDetail/:type", // 人脸库详情
    name: "faceLibraryDetail",
    component: faceLibraryDetail
  },
  {
    path: "/customAnalyze", // 访客管理
    name: "customAnalyze",
    component: customAnalyze
  },
  {
    path: "/alohaSystemPage", // 迎宾系统
    name: "alohaSystemPage",
    component: alohaSystemPage
  },
  {
    path: "/fireManagementStatics", // 消防管理统计
    name: "fireManagementStatics",
    component: fireManagementStatics
  },
  {
    // 视频播放
    path: "videoPage", // 视频播放
    name: "videoPage_index",
    component: videoPage
  },
  {
    path: "/patrolRoute", // 巡更路线
    name: "patrolRoute",
    component: patrolRoute
  },
  {
    path: "/patrolList", // 巡更列表
    name: "patrolList",
    component: patrolList
  }
];
