// import postionConf from "@/config/header-postion";

export default {
  namespaced: true,
  state: {
    positionArr: null,
    _positionInfo: {},
    _positionList: []
  },
  mutations: {
    fillPositionInfo(state, info = {}) {
      state._positionInfo = info;
    },
    fillPositionList(state, list = []) {
      state._positionList = list;
    }
  },
  getters: {
    // showPositionCasc(state, getters, rootState) {
    //   return !(rootState.routeName && postionConf.hidden.includes(rootState.routeName));
    // },
    // readonlyRootNode(state, getters, rootState) {
    //   return !!(rootState.routeName && postionConf.readonlyRootNode.includes(rootState.routeName));
    // },
    posLevel(state) {
      return state._positionList.length;
    },
    // 具体的位置信息
    positionInfo(state) {
      return state._positionInfo;
    },
    // 带层级的位置信息
    positionList(state) {
      return state._positionList;
    }
  }
};